import React, { ChangeEvent, FC, Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { RequirementsProps } from '../../tools/context';
import { useTranslation } from '../../tools/i18n';
import { getAssignedDisabilitiesIcon, getStoreQualityLogoUrl } from '../../tools/tools';
import { getCategoryKey } from '../../tools/utils';
import { Button } from '../Button';
import CustomImage from '../CustomImage';
import { Picto } from '../Picto';
import { ResponsiveImage } from '../ResponsiveImage';
import StarNote from '../StarNote';
import Toggle from '../Toggle';
import { TravelPlanerResultsContext } from './TravelPlanerResultsContext';
import { SiteFilterType, TravelPlannerResultsConfigType } from './types';

export interface SitesExploratorSiteSite extends SitesExploratorSiteBase {
  type: 'site' | undefined;
  formatted_address: string;
  modalities: string;
  free: string;
  possible_fee: string;
  tca_categ: string;
  shop_grade: string;
  brand: string | null;
  adr: string | null;
  city: string | null;
  interests: string[];
  services: string[];
  situations: string[];
  state: string | null;
}

export interface SitesExploratorSiteActivity extends SitesExploratorSiteBase {
  type: 'activity';
  _categories: string[];
  tarif: string | null;
  site: string | null;
  price_range: string | null;
  start_date: string | null;
  end_date: string | null;
  brand: string | null;
}

export interface SitesExploratorSiteBase {
  id: string | number;
  dayIndex?: number;
  pointIndex?: number;
  store_name: string;
  assigned_disabilities: Disability[];
  hebergement_accessible_france: boolean;
  quality: string;
  post_content: string;
  post_content_en: string | null;
  post_content_es: string | null;
  post_content_de: string | null;
  post_content_it: string | null;
  post_content_nl: string | null;
  urlimg: string;
  lat: number;
  lng: number;
  category?: string;
  categ_str?: string;
  tca_categ?: string;
  tourinsoft_id?: number;
  url?: string;
}

export type Disability = 'AUDITIF' | 'MENTAL' | 'MOTEUR' | 'VISUEL';
export type SitesExploratorSite = SitesExploratorSiteSite | SitesExploratorSiteActivity;
export type SortsType = 'mostPopular' | 'bestGrade';

interface SitesExploratorProps {
  config: TravelPlannerResultsConfigType;
  requirements: RequirementsProps;
  onBack: () => void;
  setShowMobileMap?: (arg0: boolean) => void;
  onChange: (dayIndex: number, point: SitesExploratorSite) => void;
  onDelete: (index: number, dayIndex?: number) => void;
  days: string[];
}

interface SelectedFilter {
  filter: SiteFilterType;
  keyFilter: string;
  andGroupKey?: string;
}

const buildAlgoliaFilters = (filters: SelectedFilter[]): string => {
  if (!filters.length) return '';
  const groups: Record<string, SelectedFilter[]> = {};
  filters.forEach((sf) => {
    const key = sf.andGroupKey ?? '__noGroup__';
    if (!groups[key]) groups[key] = [];
    groups[key].push(sf);
  });
  const groupStrings = Object.keys(groups).map((groupName) => {
    const items = groups[groupName];
    const parts = items.map((sel) => {
      const splitted = sel.filter.filters?.split('|') ?? [];
      const orBlock = splitted.map((val) => `${sel.keyFilter}:${val}`).join(' OR ');
      return splitted.length > 1 ? `(${orBlock})` : orBlock;
    });
    if (groupName === '__noGroup__') {
      return parts.length > 1 ? `(${parts.join(' OR ')})` : parts[0];
    }
    return parts.length > 1 ? `(${parts.join(' AND ')})` : parts[0];
  });
  return groupStrings.join(' AND ');
};

const SitesExplorator: FC<SitesExploratorProps> = ({
  config,
  onBack,
  requirements,
  setShowMobileMap,
  onChange,
  onDelete,
  days,
}) => {
  const [query, setQuery] = useState('');
  const { t, i18n } = useTranslation();
  const lang = i18n?.language?.split('_')?.[0];
  const {
    focusedSite,
    setFocusedSite,
    sites,
    searchSite,
    searchPaginateSite,
    isLoadingSites,
    sitesInTrip,
    setSitesInTrip,
    maxSitesPages,
    allSitesPage,
    selectedAlgoliaIndex,
    handleChangeAlgoliaIndex,
    siteConfiguration,
  } = useContext(TravelPlanerResultsContext);
  const searchDebounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const sitesExploratorRef = useRef<HTMLDivElement>(null);
  const [hideWhenInTravel, setHideWhenInTravel] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setQuery(e.target.value);
    if (typeof searchDebounceTimeout.current === 'number')
      clearTimeout(searchDebounceTimeout.current);
    searchDebounceTimeout.current = setTimeout(
      () => searchSite({ term: e.target.value, bounds: [] }),
      500
    );
  };

  const handleDayChanged = (e: ChangeEvent<HTMLSelectElement>, site: SitesExploratorSite): void => {
    onChange(parseInt(e.target.value), site);
  };

  const handleChangeSortBy = (e: ChangeEvent<HTMLSelectElement>): void => {
    const newSortBy = e.target.value as SortsType;
    searchSite({
      index: newSortBy === 'bestGrade' ? 'tca_store_replica_reviewscore' : 'tca_stores',
    });
  };

  const handleClickFilter = (
    filter: SiteFilterType,
    keyFilter: string,
    andGroupKey?: string
  ): void => {
    const idx = selectedFilters.findIndex(
      (f) =>
        f.filter.key === filter.key && f.keyFilter === keyFilter && f.andGroupKey === andGroupKey
    );
    let newSelectedFilters: SelectedFilter[];
    if (idx > -1) {
      newSelectedFilters = selectedFilters.filter((_, i) => i !== idx);
    } else {
      newSelectedFilters = [...selectedFilters, { filter, keyFilter, andGroupKey }];
    }
    setSelectedFilters(newSelectedFilters);
    const filtersStr = buildAlgoliaFilters(newSelectedFilters);
    searchSite({ filters: filtersStr ? [filtersStr] : [] });
  };

  useEffect(() => {
    setSitesInTrip(
      sites
        .filter(
          (s) =>
            (typeof s.dayIndex === 'number' || typeof s.dayIndex === 'string') &&
            (typeof s.pointIndex === 'number' || typeof s.pointIndex === 'string')
        )
        .map((s) => ({ id: s.id, dayIndex: s.dayIndex }))
    );
  }, [sites, setSitesInTrip]);

  const handleLoadMoreSites = () => {
    setFocusedSite(-1);
    searchPaginateSite().catch(console.error);
  };

  const renderActivity = (r: SitesExploratorSiteActivity): JSX.Element => {
    const siteInTrip = sitesInTrip.find((s) => s.id === r.id);
    const getFormattedDateRange = (start_date: string, end_date: string): string => {
      if (!start_date || !end_date) return '';
      const formatOptions: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
      const startDate = new Date(start_date);
      const endDate = new Date(end_date);
      const formattedStartDate = startDate?.toLocaleDateString(lang, formatOptions);
      const formattedEndDate = endDate?.toLocaleDateString(lang, formatOptions);
      if (!formattedStartDate || !formattedEndDate) return '';
      if (formattedStartDate === formattedEndDate) return formattedStartDate;
      if (
        startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth()
      ) {
        const start = startDate.getDate();
        return t('travelPlaner.PDF.dates', {
          startDate: start,
          endDate: formattedEndDate,
        });
      }
      return t('travelPlaner.PDF.dates', {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    };
    const category = getCategoryKey(r.tca_categ, config, i18n.language.split('_')?.[0] || 'fr');

    return (
      <div
        key={r.id}
        className={`site ${focusedSite === r.id ? 'focused' : ''}`}
        onClick={() => setFocusedSite(r.id)}
      >
        <div className="imageWrapper">
          {r.urlimg &&
          (r.urlimg.includes('.png') ||
            r.urlimg.includes('.jpg') ||
            r.urlimg.includes('.jpeg') ||
            r.urlimg.includes('.webp')) ? (
            <ResponsiveImage requirements={requirements} src={r.urlimg} alt={r.urlimg} />
          ) : (
            <CustomImage
              requirements={requirements}
              image={config.siteFallbackImage?.image || ''}
              alt={config.siteFallbackImage?.alt || ''}
              credit={config.siteFallbackImage?.credit || ''}
            />
          )}
          {siteInTrip ? (
            <div className="deleteSite" onClick={() => onDelete(r.pointIndex, r.dayIndex)}>
              <Picto iconKey="trash-fr" />
            </div>
          ) : undefined}
        </div>
        <div className="bodyContainer">
          <div className="name">
            {r.store_name}
            {config.showMarkersPopup && !!category ? (
              <span className="displayedCategory">{category}</span>
            ) : null}
          </div>
          <div className="tags">
            {r.tarif ? <div className="tag tarif">{r.tarif.replace('.0', '')}</div> : null}
            {r.start_date && r.end_date ? (
              <div className="tag date">
                <span>{getFormattedDateRange(r.start_date, r.end_date)}</span>
              </div>
            ) : null}
          </div>
          {(lang === 'fr' && r.post_content) || r[`post_content_${lang}`] || r.post_content_en ? (
            <div className="description">
              {lang === 'fr' ? r.post_content : (r[`post_content_${lang}`] ?? r.post_content_en)}
            </div>
          ) : null}
          <button className={`selectDate ${siteInTrip ? '' : 'notInTrip'}`}>
            <Picto iconKey="dates" />
            <div className="date">
              <select
                className={`select_${r.id}`}
                onChange={(e) => handleDayChanged(e, r)}
                value={siteInTrip?.dayIndex ?? -1}
              >
                {siteInTrip ? undefined : (
                  <option value={-1}>{t('travelPlaner.Travel.sites.add')}</option>
                )}
                {days.map((d, i) => (
                  <option key={i} value={i}>
                    {d}
                  </option>
                ))}
              </select>
            </div>
          </button>
        </div>
      </div>
    );
  };

  const renderSite = (r: SitesExploratorSiteSite): JSX.Element => {
    const siteInTrip = sitesInTrip.find((s) => s.id === r.id);
    const category = getCategoryKey(r.category, config, i18n.language.split('_')?.[0] || 'fr');

    return (
      <div
        key={r.id}
        className={`site ${focusedSite === r.id ? 'focused' : ''}`}
        onClick={() => setFocusedSite(r.id)}
      >
        <div className="imageWrapper">
          {r.urlimg &&
          (r.urlimg.includes('.png') ||
            r.urlimg.includes('.jpg') ||
            r.urlimg.includes('.jpeg') ||
            r.urlimg.includes('.webp')) ? (
            <ResponsiveImage requirements={requirements} src={r.urlimg} alt={r.urlimg} />
          ) : (
            <CustomImage
              requirements={requirements}
              image={config.siteFallbackImage?.image || ''}
              alt={config.siteFallbackImage?.alt || ''}
              credit={config.siteFallbackImage?.credit || ''}
            />
          )}
          {siteInTrip ? (
            <div className="deleteSite" onClick={() => onDelete(r.pointIndex, r.dayIndex)}>
              <Picto iconKey="trash-fr" />
            </div>
          ) : undefined}
        </div>
        <div className="bodyContainer">
          <div className="name">
            {r.store_name}
            {config.showMarkersPopup && !!category ? (
              <span className="displayedCategory">{category}</span>
            ) : (
              ''
            )}
          </div>
          <div className="gradeContainer">
            {r.shop_grade ? (
              <Fragment>
                <div className="grade">{parseFloat(r.shop_grade.replace(',', '.')).toFixed(1)}</div>
                <StarNote note={parseFloat(r.shop_grade.replace(',', '.'))} show_note={false} />
              </Fragment>
            ) : (
              <Fragment>
                <div className="grade"></div>
                <StarNote note={0} show_note={false} />
              </Fragment>
            )}
          </div>
          {r?.hebergement_accessible_france || r?.assigned_disabilities?.length || r?.quality ? (
            <div className="tourismeEtHandicap">
              {r?.quality && getStoreQualityLogoUrl(r.quality) ? (
                <div className="assignedDisabilities quality">
                  <ResponsiveImage
                    src={getStoreQualityLogoUrl(r.quality)}
                    alt={r.quality}
                    requirements={requirements}
                  />
                </div>
              ) : null}
              {r?.hebergement_accessible_france && !r.assigned_disabilities?.length ? (
                <div className="assignedDisabilities">
                  <ResponsiveImage
                    src="https://cdn.cibler.io/static/france/Logos_TourismeEtHandicap/Default.png"
                    alt="Logo Tourisme Et Handicap"
                    requirements={requirements}
                  />
                </div>
              ) : null}
              {r?.assigned_disabilities?.length ? (
                <div className="assignedDisabilities">
                  <ResponsiveImage
                    src={getAssignedDisabilitiesIcon(r.assigned_disabilities)}
                    alt={r.assigned_disabilities.toString()}
                    requirements={requirements}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {(lang === 'fr' && r.post_content) || r[`post_content_${lang}`] || r.post_content_en ? (
            <div className="description">
              {lang === 'fr' ? r.post_content : (r[`post_content_${lang}`] ?? r.post_content_en)}
            </div>
          ) : null}
          <button className={`selectDate ${siteInTrip ? '' : 'notInTrip'}`}>
            <Picto iconKey="dates" />
            <div className="date">
              <select
                className={`select_${r.id}`}
                onChange={(e) => handleDayChanged(e, r)}
                value={siteInTrip?.dayIndex ?? -1}
              >
                {siteInTrip ? undefined : (
                  <option value={-1}>{t('travelPlaner.Travel.sites.add')}</option>
                )}
                {days.map((d, i) => (
                  <option key={i} value={i}>
                    {d}
                  </option>
                ))}
              </select>
            </div>
          </button>
        </div>
      </div>
    );
  };

  const ToggleItems = [
    {
      id: 'tca_stores',
      children: (
        <Fragment>
          <Picto iconKey="location-fr" className="locationPicto" />
          <span>{t('travelPlaner.Travel.sites.toggle.stores')}</span>
        </Fragment>
      ),
    },
    {
      id: 'activities',
      children: (
        <Fragment>
          <Picto iconKey="france-calendar" className="calendarPicto" />
          <span>{t('travelPlaner.Travel.sites.toggle.activities')}</span>
        </Fragment>
      ),
    },
  ];

  useEffect(() => {
    if (focusedSite === -1) return;
    const siteElement = document.querySelector(`.site.focused`);
    if (!siteElement) return;
    sitesExploratorRef.current?.scrollTo({
      top: siteElement.getBoundingClientRect().top + sitesExploratorRef.current.scrollTop - 200,
      behavior: 'smooth',
    });
  }, [focusedSite]);

  const disabilitiesFilters = [
    {
      imgUrl: 'https://cdn.cibler.io/static/france/Logos_TourismeEtHandicap/Tourisme_Handi_A.jpg',
      filter: 'AUDITIF',
    },
    {
      imgUrl: 'https://cdn.cibler.io/static/france/Logos_TourismeEtHandicap/Tourisme_Handi_M.jpg',
      filter: 'MENTAL',
    },
    {
      imgUrl: 'https://cdn.cibler.io/static/france/Logos_TourismeEtHandicap/Tourisme_Handi_Me.jpg',
      filter: 'MOTEUR',
    },
    {
      imgUrl: 'https://cdn.cibler.io/static/france/Logos_TourismeEtHandicap/Tourisme_Handi_V.jpg',
      filter: 'VISUEL',
    },
  ];

  return (
    <div className="sitesExplorator" ref={sitesExploratorRef}>
      <div
        className="backButton"
        onClick={() => {
          onBack();
          setShowMobileMap?.(false);
        }}
      >
        {siteConfiguration.withSVG ? (
          <Button
            textLabel="travelPlaner.Travel.sites.back"
            withSVG={siteConfiguration.withSVG}
            usePicto={false}
          />
        ) : (
          <>
            <Picto iconKey="arrow-fr" />
            <span>{t('travelPlaner.Travel.sites.back')}</span>
          </>
        )}
      </div>
      <div className="container">
        <div className="controls">
          <div className="title">{t('travelPlaner.Travel.sites.title')}</div>
          <div className="inputBlock">
            <Picto iconKey="search-fr" height="16" width="16" />
            <input
              value={query}
              onChange={(e) => handleInput(e)}
              placeholder={t('travelPlaner.Travel.sites.searchPlaceholder')}
            />
          </div>
          {requirements?.config?.key === 'tca_oeno' ? (
            <Toggle
              items={ToggleItems}
              onSelect={handleChangeAlgoliaIndex}
              selectedId={selectedAlgoliaIndex}
            />
          ) : null}
          {config?.sitesFilters?.length ? (
            <div className="filtersContainer">
              <div className="filters">
                {config.sitesFilters.map((filter, index) => (
                  <Button
                    key={index}
                    theme="pro"
                    className={`filter ${
                      selectedFilters.some(
                        (f) => f.filter.key === filter.key && f.keyFilter === '_categories'
                      )
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() => handleClickFilter(filter, '_categories')}
                  >
                    <span>{t(`travelPlaner.Travel.sites.filters.${filter.key}`)}</span>
                  </Button>
                ))}
              </div>
            </div>
          ) : null}
          {config.showDisabilitiesFilters ? (
            <div className="filtersContainer">
              <div className="filters">
                {disabilitiesFilters.map((filter, index) => (
                  <Button
                    key={index}
                    theme="pro"
                    className={`disabilitiesFilter ${
                      selectedFilters.some(
                        (f) =>
                          f.filter.key === filter.filter && f.keyFilter === 'assigned_disabilities'
                      )
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() =>
                      handleClickFilter(
                        { key: filter.filter, filters: filter.filter },
                        'assigned_disabilities',
                        'assigned_disabilities'
                      )
                    }
                  >
                    <ResponsiveImage
                      src={filter.imgUrl}
                      alt={filter.filter}
                      requirements={requirements}
                    />
                  </Button>
                ))}
              </div>
            </div>
          ) : null}
          <div className="hideAndSort">
            <div className="hideCheckboxContainer">
              <input
                type="checkbox"
                id="hideWhenInTravel"
                value={hideWhenInTravel ? '1' : '0'}
                onChange={() => setHideWhenInTravel((p) => !p)}
              />
              <label htmlFor="hideWhenInTravel">
                {t('travelPlaner.Travel.sites.hideWhenInTravel')}
              </label>
            </div>
            {selectedAlgoliaIndex !== 'activities' ? (
              <div className="sortContainer">
                <div>{t('travelPlaner.Travel.sites.sortBy')}</div>
                <select onChange={handleChangeSortBy}>
                  <option value="mostPopular">{t('travelPlaner.Travel.sites.mostPopular')}</option>
                  <option value="bestGrade">{t('travelPlaner.Travel.sites.bestGrade')}</option>
                </select>
              </div>
            ) : null}
          </div>
        </div>
        <div className="results">
          {sites?.length ? (
            sites
              .filter((s) => {
                const siteInTrip =
                  typeof s.dayIndex === 'number' && typeof s.pointIndex === 'number';
                return !hideWhenInTravel || !siteInTrip;
              })
              .map((site) => (site.type === 'activity' ? renderActivity(site) : renderSite(site)))
          ) : (
            <div className="noResults">{t('travelPlaner.Travel.sites.noResults')}</div>
          )}
        </div>
        <div className="sitesPagination">
          {isLoadingSites ? (
            <Oval color="#000000" height={30} width={30} />
          ) : allSitesPage + 1 >= maxSitesPages ? null : (
            <Button onClick={handleLoadMoreSites} className="loadMoreButton">
              {t('travelPlaner.Travel.sites.loadMore')}
              <Picto iconKey="caret-down" />
            </Button>
          )}
        </div>
      </div>
      <div className="footer">
        <div className="showMap" onClick={() => setShowMobileMap?.(true)}>
          <Picto iconKey="guide-icon" />
          <span>{t('travelPlaner.Travel.showMap')}</span>
        </div>
      </div>
      <button></button>
    </div>
  );
};

export default SitesExplorator;
