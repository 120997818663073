import React from 'react';
import { PictoProps } from './index';

export const TravelSoloV3 = (props: PictoProps) => (
  <svg
    width={props.width || '40'}
    height={props.height || '40'}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1666 11.6667C24.1666 13.9679 22.3012 15.8333 20 15.8333C17.6988 15.8333 15.8333 13.9679 15.8333 11.6667C15.8333 9.36548 17.6988 7.5 20 7.5C22.3012 7.5 24.1666 9.36548 24.1666 11.6667ZM18.3333 17.5H21.6667C24.8917 17.5 27.5 20.1083 27.5 23.3333V31.6667C27.5 31.8917 27.4167 32.1 27.2583 32.2583C27.1 32.4167 26.8917 32.5 26.6667 32.5H13.3333C13.1083 32.5 12.9 32.4167 12.7417 32.2583C12.5917 32.1 12.5 31.8917 12.5 31.6667V23.3333C12.5 20.1083 15.1083 17.5 18.3333 17.5Z"
      fill={props.fill || '#000000'}
    />
  </svg>
);
