import React from 'react';
import { PictoProps } from './index';

export const TravelFamilyV3 = (props: PictoProps) => (
  <svg
    width={props.width || '40'}
    height={props.height || '40'}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4336 15.8333C16.7348 15.8333 18.6003 13.9679 18.6003 11.6667C18.6003 9.36548 16.7348 7.5 14.4336 7.5C12.1324 7.5 10.2669 9.36548 10.2669 11.6667C10.2669 13.9679 12.1324 15.8333 14.4336 15.8333ZM25.5669 15.8333C27.8681 15.8333 29.7336 13.9679 29.7336 11.6667C29.7336 9.36548 27.8681 7.5 25.5669 7.5C23.2657 7.5 21.4002 9.36548 21.4002 11.6667C21.4002 13.9679 23.2657 15.8333 25.5669 15.8333ZM23.9003 17.5H27.2336C30.4586 17.5 33.0669 20.1083 33.0669 23.3333V31.6667C33.0669 31.8917 32.9836 32.1 32.8253 32.2583C32.6669 32.4167 32.4586 32.5 32.2336 32.5H25.2503V30.1333C25.2503 27.875 23.4253 26.05 21.1669 26.05H18.8336C16.5753 26.05 14.7503 27.875 14.7503 30.1333V32.5H7.76693C7.54193 32.5 7.33359 32.4167 7.17526 32.2583C7.01693 32.1 6.93359 31.8917 6.93359 31.6667V23.3333C6.93359 20.1083 9.54193 17.5 12.7669 17.5H16.1003C17.6003 17.5 18.9669 18.0667 20.0003 19C21.0336 18.075 22.4003 17.5 23.9003 17.5ZM17.0836 21.9667C17.0836 23.575 18.3919 24.8833 20.0003 24.8833C21.6086 24.8833 22.9169 23.575 22.9169 21.9667C22.9169 20.3583 21.6086 19.05 20.0003 19.05C18.3919 19.05 17.0836 20.3583 17.0836 21.9667Z"
      fill={props.fill || '#000000'}
    />
  </svg>
);
