import React, { Suspense } from 'react';
import { useIsClient } from '../../tools/hooks';
import { LeafletMapProps } from './LeafletMap';

export interface LeafletMapLazyProps extends LeafletMapProps {}

const LeafletMapLazy: React.FC<LeafletMapLazyProps> = (props) => {
  const isClient = useIsClient();
  const LeafletMap = React.lazy(() => import('./LeafletMap'));

  if (!isClient) return null;

  return (
    <Suspense fallback={<div>Loading map...</div>}>
      <LeafletMap key={Date.now()} {...props} />
    </Suspense>
  );
};

export default LeafletMapLazy;
