/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { OverLayParameter } from '../../App';
import * as Configurations from '../../configurations';
import { useTracking } from '../../tools/analytics';
import { fetchPartnerStates, PartnerState } from '../../tools/apiTools';
import { RequirementsProps } from '../../tools/context';
import { useTranslation } from '../../tools/i18n';
import { getSanityClient } from '../../tools/sanity';
import {
  getCookies,
  getOrCreateCiblerId,
  getQueryString,
  replaceQueryString,
} from '../../tools/tools';
import { Picto } from '../Picto';
import { ResponsiveImage } from '../ResponsiveImage';
import { TravelPlannerResultsSanityProps } from '../TravelPlanerHub';
import { Budget } from './Budget';
import { Composition } from './Composition';
import { CompositionAndPace } from './CompositionAndPace';
import { DayNumber } from './DayNumber';
import { Error } from './Error';
import { HourSliders } from './HourSliders';
import { Interests } from './Interests';
import { Loader } from './Loader';
import { Lodging } from './Lodging';
import { NewInterests } from './NewInterests';
import { Months, PeriodsOrDates } from './PeriodsOrDates';
import { Search } from './Search';
import { SearchMap } from './SearchMap';
import { StartDate } from './StartDate';
import { Times } from './Times';
import {
  TravelPlannerConfigType,
  TravelPlannerNewInterestsType,
  TravelPlannerSearchType,
  TravelPlannerStepType,
  TravelPlannerVisitMoovConfig,
} from './types';

export interface TravelPlanerProps {
  agentId: string;
  agentKey: string;
  config: TravelPlannerConfigType;
  componentStates: {
    travelPlannerResults: TravelPlannerResultsSanityProps;
    partnerStates?: PartnerState[];
  };
  hideAgentKeyCss?: boolean;
  setOverlay: (p: OverLayParameter) => void;
  requirements: RequirementsProps;
  siteConfiguration: any;
}

export interface ChatBotComponentProps {
  config: TravelPlannerConfigType;
  step: TravelPlannerStepType;
  requirements?: RequirementsProps;
  agentId: string;
  initialData?: any;
  stateData?: any;
  setStateData?: (p: any) => void;
  key?: string;
  callback: (p: any) => void;
  back: () => void;
  siteConfiguration: any;
}

const Renderer = (props: TravelPlanerProps) => {
  const { t: t18n, i18n } = useTranslation();
  const { requirements, agentId, agentKey, config, componentStates, hideAgentKeyCss, setOverlay } =
    props;
  const { travelPlannerResults, partnerStates = [] } = componentStates;

  const initialStep: TravelPlannerSearchType = {
    _type: 'travelPlannerSearch',
    _key: 'd88e9f55d12963834',
    hideStepBar: false,
    key: 'search',
    label: 'Recherche',
  };

  // Use the agentKey to have different configurations for the same partner
  useEffect(() => {
    if (agentKey?.length) {
      // Add the configuration for the agent
      requirements.config =
        Configurations[`${requirements.partner}_${agentKey}`] || requirements.config;

      // Add custom CSS
      if (!hideAgentKeyCss) {
        const head = document.getElementsByTagName('head')[0];
        const oldLink = document.getElementById(`customCSS_${requirements.config.key}`);
        const partnerDefaultStyle = document.getElementById('customCSS');
        if (partnerDefaultStyle) {
          partnerDefaultStyle.remove();
        }
        if (!oldLink) {
          const link = document.createElement('link');
          link.id = `customCSS_${requirements.config.key}}`;
          link.rel = 'stylesheet';
          link.type = 'text/css';
          link.href = `//${window.location.host}/custom/${requirements.config.key}.css`;
          link.media = 'all';
          head.appendChild(link);
        }
      }

      if (!i18n.language.includes(agentKey)) {
        const newLanguage = `${i18n.language}_${agentKey}`;
        i18n
          .changeLanguage(newLanguage, (err, t) => {
            if (err) return console.error('something went wrong loading', err);
          })
          .catch((e) => console.error('Error changing language', e));
      }

      setStep({
        ...(config?.steps?.[0] || initialStep),
        index: 0,
      });
    }
  }, [agentKey]);

  const [step, setStep] = useState<TravelPlannerStepType>({
    ...(config?.steps?.[0] || initialStep),
    index: 0,
  });
  const [showError, setShowError] = useState<boolean>(false);
  const [datas, setDatas] = useState<any>(getInitialData());
  const isFranceFr = requirements.config.key === 'france';
  const isHuttopia = requirements.config.key === 'huttopia';

  // Function to get the initial data
  function getInitialData() {
    const initialData = {
      lat: '48.8588897', // Default latitude value
      lon: '2.320041', // Default longitude value
      cityKey: 'paris', // Default cityKey value,
      cityLabel: 'Paris', // Default cityLabel value,
      selectedFavorite: false,
    };

    if (config?.favorites?.length) {
      const favorite = config.favorites[0];
      initialData.lat = favorite.lat.toString();
      initialData.lon = favorite.lng.toString();
      initialData.cityKey = favorite.key;
      initialData.cityLabel = favorite.label;
      initialData.selectedFavorite = true;
    }

    return initialData;
  }

  const { trackEvent } = useTracking();

  const stepsList = {
    search: Search,
    searchMap: SearchMap,
    timePerDay: Times,
    tripLength: DayNumber,
    startDate: StartDate,
    periodsOrDates: PeriodsOrDates,
    hoursSliders: HourSliders,
    composition: Composition,
    compositionAndPace: CompositionAndPace,
    interests: Interests,
    newInterests: NewInterests,
    lodging: Lodging,
    budget: Budget,
    loader: Loader,
  };
  const { steps: travelSteps } = config || {};
  const steps =
    travelSteps?.map((s: TravelPlannerStepType, index: number) => ({
      ...s,
      index,
    })) || [];
  const stepLabels = {
    search: 'Lieu',
    searchMap: 'Lieu',
    timePerDay: 'Durée',
    tripLength: 'Durée du voyage',
    startDate: 'Date de départ',
    startMonth: 'Période du voyage',
    hoursSliders: "Heures d'activités",
    composition: 'Total voyageurs',
    pace: 'Rythme',
    interests: "Centres d'intérêts",
    newInterests: "Centres d'intérêts",
    lodging: 'Hébergement',
    budget: 'Budget',
  };
  const stepNumber = Object.keys(steps).length;

  useEffect(() => {
    trackEvent(`guide_page_${step.key}`);
  }, [step]);
  useEffect(() => {
    if (!showError) return;
    trackEvent(`guide_page_Error`);
  }, [showError]);

  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const formatCityKey = (key: string): string => {
    console.log('key', key);
    return key?.split(',')?.[0]?.trim()?.toLowerCase();
  };

  const callBot = async (inputs) => {
    inputs.lang = i18n.language;

    inputs.search = inputs.search || inputs.searchMap || inputs.cityLabel;
    inputs.searchMap = inputs.searchMap || inputs.search || inputs.cityLabel;
    const lang = inputs.lang.split('_')[0] === 'fr' ? 'fr' : 'en';

    delete inputs.cityLabel;

    let prompt = `**Langue:** ${lang}. `;
    prompt += Object.keys(inputs)
      .map((t) => {
        switch (t) {
          case 'lon':
          case 'lat':
          case 'cityKey':
          case 'searchMap':
            return '';
          case 'tripLength':
            return `**${stepLabels[t]} :** ${inputs[t]} jour(s).`;
          case 'hoursSliders':
            return `**${stepLabels[t]} :** ${inputs[t]
              .map((h, i) => `Jour ${i + 1} de ${h[0]}h à ${h[1]}h`)
              .join(', ')}.`;
          case 'interests':
            return `**${stepLabels[t]} :** Sur une echelle de 1 à 3, ${Object.keys(inputs[t])
              .map(
                (d) =>
                  `${t18n(`travelPlaner.Interests.keys.${d}`)}: ${
                    typeof inputs[t][d] === 'object'
                      ? inputs[t][d].note
                      : JSON.stringify(inputs[t][d])
                  }`
              )
              .join(',')}`;
          case 'startDate':
            return `**${stepLabels[t]} :** ${moment(inputs[t].toString()).format('MMMM')}.`;
          case 'startMonth':
            return `**${stepLabels[t]} :** ${Months[inputs[t]]}`;
          case 'lodging':
            // if (!inputs[t].hotel?.name && !inputs[t].address?.name)
            return '';
          // return `**${stepLabels[t]} :** ${inputs[t].hotel?.name ?? inputs[t].address?.name}.`;
          case 'budget':
          case 'composition':
          case 'pace':
            return `**${stepLabels[t]} :** ${t18n(`travelPlaner.${capitalize(t)}.${inputs[t]}`)}.`;
          case 'search':
            return `**${stepLabels[t]} :** ${Array.isArray(inputs[t]) ? inputs[t].map((l) => l.value).join(' | ') : inputs.searchMap.value || inputs.searchMap}.`;
          default:
            return stepLabels[t] ? `**${stepLabels[t]} :** ${inputs[t]}.` : '';
        }
      })
      .join(' ');

    prompt = prompt.replace(/(\s{2,})|(^\s+)|(\s+$)/g, ' ').trim(); // Remove duplicated spaces or trailing spaces
    const cacheKey = 'v:1 prompt:' + prompt;
    const debug = getQueryString('debug');
    const radiusFromQueryString = getQueryString('radius');
    const id = getOrCreateCiblerId();

    const apiRoute = `api/poi/journey${config.botVersion !== 'v1' ? `_${config.botVersion}` : ''}`;

    let apiUrl = `${
      requirements.endpoint
    }/${apiRoute}?cacheKey=${encodeURIComponent(cacheKey)}&customer=${
      requirements.config.customerId
    }`;
    if (agentId) apiUrl += `&agent=${agentId}`;
    apiUrl += `&ciblerId=${id}`;
    // Add debug=true if debug is present in the URL
    if (debug) apiUrl += `&debug=true`;
    let start = Date.now(); // Capture the time before making the API call

    const { _type, ...visitMoovConfig } =
      config.botVersion === 'v2' ? config.visitMoovConfig : ({} as TravelPlannerVisitMoovConfig);

    // console.log('inputs:', inputs);
    // console.log('datas:', datas);

    const body = Array.isArray(inputs.searchMap)
      ? {
          prompt: prompt,
          lon: inputs.searchMap[0].coordinates?.lon?.toString() || '',
          lat: inputs.searchMap[0].coordinates?.lat?.toString() || '',
          cityKey: inputs.searchMap.map((item) => item.value).join(' | '),
          radius: radiusFromQueryString
            ? parseInt(radiusFromQueryString)
            : (inputs.radius ?? config.radius),
          multiplePOI: config.multiplePOI || false,
          inputs: {
            ...inputs,
            lon: inputs.searchMap[0].coordinates?.lon?.toString() || '',
            lat: inputs.searchMap[0].coordinates?.lat?.toString() || '',
            cityKey: inputs.searchMap.map((item) => item.value).join(' | '),
            cityKeys: inputs.searchMap.map((item) => formatCityKey(item.value)),
            coordinates: inputs.searchMap.map((item) => item.coordinates),
            searchMap: inputs.searchMap.map((item) => item.label).join(' | '),
            search: inputs.searchMap.map((item) => item.label).join(' | '),
            postalCode: inputs.searchMap[0].postalCode,
            postalCodes: inputs.searchMap.map((item) => item.postalCode),
          },
          visitMoovConfig: visitMoovConfig ?? undefined,
        }
      : {
          prompt: prompt,
          lon: inputs.searchMap.coordinates?.lon?.toString() || inputs.lon,
          lat: inputs.searchMap.coordinates?.lat?.toString() || inputs.lat,
          cityKey: inputs.search.value || inputs.searchMap.value || formatCityKey(inputs.search),
          radius: radiusFromQueryString
            ? parseInt(radiusFromQueryString)
            : (inputs.radius ?? config.radius),
          multiplePOI: config.multiplePOI || false,
          inputs: {
            ...inputs,
            lon: inputs.searchMap.coordinates?.lon?.toString() || '',
            lat: inputs.searchMap.coordinates?.lat?.toString() || '',
            cityKey:
              inputs.search.value ||
              inputs.searchMap.value ||
              formatCityKey(inputs.search) ||
              formatCityKey(inputs.searchMap),
            searchMap: inputs.searchMap.value || inputs.searchMap,
            search: inputs.search.value || inputs.search,
            postalCode: inputs.searchMap.postalCode,
          },
          visitMoovConfig: visitMoovConfig ?? undefined,
        };

    // console.log('callBot body:', JSON.stringify(body, null, 2));
    // console.log('callBot body:', JSON.stringify(body, null, 2));
    // console.log('callBot API URL:', apiUrl);
    // console.log('callBot headers:', {
    //   'Content-Type': 'application/json',
    //   Accept: 'application/json',
    // });

    if (config.botVersion === 'v4') {
      apiUrl = 'https://clients.wearegenial.com/api/v1/travel/huttopia/generate';
    }
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      });
      let apiCallDuration = Date.now() - start;

      let timeoutValue = 8000 - apiCallDuration;
      timeoutValue = Math.max(timeoutValue, 1000);

      if (response.ok) {
        console.log('🟦 API response (response.ok):', response);
        trackEvent('guide_loading_success');
        setTimeout(async () => {
          const textResult = await response.text();
          console.log('API response (textResult):', textResult);
          await waitFirstDay(textResult); // Wait for the first day to be generated

          document.cookie = `lastTrip=${textResult}`;
          const itineraryPath = travelPlannerResults?.path?.current || '/itineraire';
          window.location.href = `${itineraryPath}/${textResult}?auth=${id}`;
        }, timeoutValue); // Use the adjusted timeout value here
        return;
      }
      trackEvent('guide_loading_error');
      setShowError(true);
    } catch (e) {
      console.log('error:', e);
      setShowError(true);
    }
  };

  const waitFirstDay = async (tripId: string) => {
    return new Promise((resolve, reject) => {
      const endTime = Date.now() + 5 * 60 * 1000; // 5 minutes in milliseconds

      const interval = setInterval(async () => {
        try {
          const response = await fetch(
            `${requirements.endpoint}/api/poi/journey/${tripId}/details?customer=${requirements?.config?.customerId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            }
          );

          const data = await response.json();

          if (data?.travelData?.trip) {
            console.log('Journey details data:', data);
            clearInterval(interval);
            resolve(data);
          }

          if (Date.now() > endTime) {
            clearInterval(interval);
            reject('Timed out waiting for the first day to be generated');
          }
        } catch (error) {
          clearInterval(interval);
          reject(error);
        }
      }, 1000);
    });
  };

  const stepCallback = (p: any) => {
    const tempData = { ...datas };
    tempData[step.key] = p;
    if (step.key === 'periodsOrDates') {
      tempData['tripLength'] = p.tripLength;
      if (typeof p.startDate !== 'undefined') tempData['startDate'] = p.startDate;
      if (typeof p.month !== 'undefined') tempData['startMonth'] = p.month;
    } else if (step.key === 'compositionAndPace') {
      tempData['composition'] = p.composition;
      tempData['pace'] = p.pace;
    } else if (step.key === 'newInterests') {
      const interestsConfig = config.steps.find(
        (s) => s.key === 'newInterests'
      ) as TravelPlannerNewInterestsType;
      const interests = {};
      Object.keys(p)?.forEach((k) => {
        if (isHuttopia) {
          interests[k] = p[k];
        } else {
          interests[k] = {
            note: p[k],
            filters: interestsConfig?.list?.find((f) => f.key === k)?.categories?.split('|') || [],
          };
        }
      });
      tempData['interests'] = interests;
      delete tempData['newInterests'];
    } else if (step.key === 'searchMap' || step.key === 'search') {
      tempData[step.key] = p.label || p.description || p.searchMap;

      tempData['cityKey'] = p.key;
      tempData['cityLabel'] = p.label || p.description;
      tempData['lon'] = p.lng?.toString() || datas.lon;
      tempData['lat'] = p.lat?.toString() || datas.lat;
      tempData['radius'] = p.radius;
      tempData['selectedFavorite'] = true;
    } else {
      tempData[step.key] = p;
    }
    setDatas(tempData);

    if (step.index >= stepNumber - 2) {
      const cookies = getCookies();
      if (cookies.lastTrip && !cookies.loginToken && requirements.config.travelForceLogin) {
        const queries = {
          state: btoa(
            encodeURIComponent(
              JSON.stringify({
                datas: tempData,
                step: step.index,
              })
            )
          ),
        };
        setOverlay({ key: 'loginPopup', context: { queries } });

        return;
      }
      callBot(tempData);
    }

    setStep((prev) => steps[prev.index + 1]);
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    const cookies = getCookies();
    const strState = getQueryString('state');
    if (strState && cookies.loginToken && requirements.config.travelForceLogin) {
      replaceQueryString('state', null);
      const state = JSON.parse(decodeURIComponent(atob(strState)));
      setDatas(state.datas);
      setStep(steps[state.step]);
      if (state.step >= stepNumber - 1) {
        callBot(state.datas);
        setStep((p) => steps[p.index + 1]);
      }
    }
  }, []);

  if (!step || !config) return null;

  const Component = stepsList[step.key];
  return (
    <div className={`travelPlaner step_${step.index}`}>
      {config.hideHeader ? null : datas.cityKey && step.index ? (
        <ResponsiveImage
          className="headerImage"
          requirements={requirements}
          src={`https://cdn.cibler.io/static/tca/v2/travelPlaner/${datas.cityKey}.jpg`}
          alt={datas.cityKey}
          notLazy
        />
      ) : (
        <ResponsiveImage
          className="headerImage"
          requirements={props.requirements}
          src={
            step.headerImage ??
            'https://cdn.cibler.io/static/tca/v2/travelPlaner/header_standard.jpg'
          }
          alt="header_standard"
          retry={3}
        />
      )}
      <div className="head">
        {!!step.index && step.index < stepNumber ? (
          <button
            className="previous"
            onClick={() => {
              setStep((p) => steps[p.index - 1]);
              window.scrollTo({ top: 0 });
            }}
          >
            <Picto iconKey={'arrow-left'} />
          </button>
        ) : null}
        {step.headImage ? (
          <ResponsiveImage
            className="headImage"
            requirements={props.requirements}
            src={step.headImage}
            alt="header_standard"
            retry={3}
          />
        ) : null}
        {step.hideStepBar ? null : (
          <div className="progressionBar ">
            {Object.keys(steps).map((s, i) => (
              <Fragment key={`bar_${encodeURIComponent(s)}`}>
                <div key={`bar_${encodeURIComponent(s)}`} className={step.index >= i ? 'full' : ''}>
                  {isFranceFr || isHuttopia ? i + 1 : ''}
                </div>
                {isFranceFr || isHuttopia ? (
                  i < stepNumber - 1 ? (
                    <div
                      className={
                        step.index > i ? 'progressionBar__dash full' : 'progressionBar__dash'
                      }
                    />
                  ) : undefined
                ) : undefined}
              </Fragment>
            ))}
          </div>
        )}
      </div>
      <div className={`travelPlanerContent ${config?.newUI ? 'newTravelPlanerUI' : ''}`}>
        {showError ? (
          <Error
            callback={() => {
              setStep(steps[0]);
              setShowError(false);
            }}
          />
        ) : (
          <Component
            key={step.key}
            callback={stepCallback}
            agentId={agentId}
            back={
              step.index > 0
                ? () => {
                    setStep((p) => steps[p.index - 1]);
                    window.scrollTo({ top: 0 });
                  }
                : undefined
            }
            config={config}
            step={step}
            initialData={datas[step.key]}
            stateData={datas}
            setStateData={setDatas}
            requirements={requirements}
            partnerStates={partnerStates}
            siteConfiguration={props.siteConfiguration}
          />
        )}
      </div>
    </div>
  );
};

const preloader = async (data: any, requirements: RequirementsProps) => {
  const PARTNERS_WITH_PREDICTIONS = ['huttopia'];

  const client = getSanityClient(requirements);
  const query = `*[_type == "page" && (path.current=="${requirements.path}") && !(_id in path("drafts.**"))][0]`;
  const results = await client.fetch(query);
  const config = results?.content?.[0]?.config || {};
  const travelPlannerResultsRef = config?.travelPlannerResultsRef?._ref;

  const travelPlannerResults = travelPlannerResultsRef
    ? await client.fetch(`*[_id == "${travelPlannerResultsRef}" && !(_id in path("drafts.**"))][0]`)
    : undefined;

  const isPartnerWithPredictions = PARTNERS_WITH_PREDICTIONS.includes(requirements.config.key);
  const partnerStates = isPartnerWithPredictions
    ? (await fetchPartnerStates(requirements.config.key))?.states
    : undefined;

  return {
    travelPlannerResults,
    partnerStates,
  };
};

const TravelPlaner = {
  Renderer,
  preloader,
};
export default TravelPlaner;
export { TravelPlaner };
