import React from 'react';
import { PictoProps } from './index';

export const TravelCoupleV3 = (props: PictoProps) => (
  <svg
    width={props.width || '40'}
    height={props.height || '40'}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3336 11.6667C18.3336 13.9679 16.4682 15.8333 14.167 15.8333C11.8658 15.8333 10.0003 13.9679 10.0003 11.6667C10.0003 9.36548 11.8658 7.5 14.167 7.5C16.4682 7.5 18.3336 9.36548 18.3336 11.6667ZM30.0003 11.6667C30.0003 13.9679 28.1348 15.8333 25.8337 15.8333C23.5325 15.8333 21.667 13.9679 21.667 11.6667C21.667 9.36548 23.5325 7.5 25.8337 7.5C28.1348 7.5 30.0003 9.36548 30.0003 11.6667ZM27.5003 17.5H24.167C20.942 17.5 18.3337 20.1083 18.3337 23.3333V31.6667C18.3337 31.8917 18.417 32.1 18.5753 32.2583C18.7337 32.4167 18.942 32.5 19.167 32.5H32.5003C32.7253 32.5 32.9337 32.4167 33.092 32.2583C33.2503 32.1 33.3337 31.8917 33.3337 31.6667V23.3333C33.3337 20.1083 30.7253 17.5 27.5003 17.5ZM18.6753 18.2417C17.4337 19.5833 16.667 21.3667 16.667 23.3333V31.6667C16.667 31.95 16.7253 32.2333 16.817 32.5H7.50033C7.27533 32.5 7.06699 32.4167 6.90866 32.2583C6.75033 32.1 6.66699 31.8917 6.66699 31.6667V23.3333C6.66699 20.1083 9.27533 17.5 12.5003 17.5H15.8337C16.867 17.5 17.8337 17.775 18.6753 18.2417Z"
      fill={props.fill || '#000000'}
    />
  </svg>
);
