import React from 'react';
import { PictoProps } from './index';

export const WaterDrop = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '25'}
    height={height || '26'}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5197 2.64453C12.5197 2.64453 0.42757 14.5794 8.06442 21.3732C13.4685 26.1812 19.5083 21.2909 19.4635 16.7849C19.3973 10.241 12.5197 2.64453 12.5197 2.64453Z"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linejoin="round"
    />
    <path
      d="M8.55664 16.6523C8.87855 17.5078 9.40845 18.306 10.1956 19.0181C10.8894 19.6473 11.5846 20.1103 12.2696 20.4337"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
