import React from 'react';
import { PictoProps } from './index';

export const TravelFriendsV3 = (props: PictoProps) => (
  <svg
    width={props.width || '40'}
    height={props.height || '40'}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 15.8333C22.3012 15.8333 24.1667 13.9679 24.1667 11.6667C24.1667 9.36548 22.3012 7.5 20 7.5C17.6988 7.5 15.8333 9.36548 15.8333 11.6667C15.8333 13.9679 17.6988 15.8333 20 15.8333ZM14.1667 15C14.1667 17.3012 12.3012 19.1666 10 19.1666C7.69882 19.1666 5.83334 17.3012 5.83334 15C5.83334 12.6988 7.69882 10.8333 10 10.8333C12.3012 10.8333 14.1667 12.6988 14.1667 15ZM34.1667 15C34.1667 17.3012 32.3012 19.1666 30 19.1666C27.6988 19.1666 25.8333 17.3012 25.8333 15C25.8333 12.6988 27.6988 10.8333 30 10.8333C32.3012 10.8333 34.1667 12.6988 34.1667 15ZM18.3333 17.5H21.6667C24.8917 17.5 27.5 20.1083 27.5 23.3333V31.6667C27.5 31.8917 27.4167 32.1 27.2583 32.2583C27.1 32.4167 26.8917 32.5 26.6667 32.5H13.3333C13.1083 32.5 12.9 32.4167 12.7417 32.2583C12.5833 32.1 12.5 31.8917 12.5 31.6667V23.3333C12.5 20.1083 15.1083 17.5 18.3333 17.5ZM31.6667 20.8333H28.7417C29.0167 21.6166 29.1667 22.4583 29.1667 23.3333V31.6666C29.1667 31.95 29.1167 32.2333 29.025 32.5H36.6667C36.8917 32.5 37.1 32.4166 37.2583 32.2583C37.4167 32.1 37.5 31.8916 37.5 31.6666V26.6666C37.5 23.4416 34.8917 20.8333 31.6667 20.8333ZM8.33333 20.8333H11.2583C10.9833 21.6166 10.8333 22.4583 10.8333 23.3333V31.6666C10.8333 31.95 10.8833 32.2333 10.975 32.5H3.33333C3.10833 32.5 2.9 32.4166 2.74167 32.2583C2.58333 32.1 2.5 31.8916 2.5 31.6666V26.6666C2.5 23.4416 5.10833 20.8333 8.33333 20.8333Z"
      fill={props.fill || '#000000'}
    />
  </svg>
);
