import React, { useEffect, useState } from 'react';
import { fetchArticlesData } from '../../tools/apiTools';
import { useTranslation } from '../../tools/i18n';
import StaticCarousel from '../Carousel/StaticCarousel';
import { Picto } from '../Picto';

interface Article {
  title: string;
  url: string;
  image_url: string;
  category: string;
  location: string;
  key?: string;
  image?: string;
  noNote?: boolean;
  place?: string;
}

interface ArticlesProps {
  requirements: any;
  cityKey: string;
  postalCode?: string;
}

const defaultFranceArticles: Article[] = [
  {
    title: '8 réflexes pour voyager durable en France',
    url: 'https://www.france.fr/fr/article/voyager-durable-en-france/',
    image_url: 'https://asset-prod.france.fr/chica_lago_4c4139f1a4.jpg',
    category: 'durable',
    location: 'France',
  },
  {
    title: 'Nos pépites pour voyager en France hors des sentiers battus !',
    url: 'https://www.france.fr/fr/article/pepites-tresors-meconnus-france-hors-des-sentiers-battus/',
    image_url: 'https://asset-prod.france.fr/Cha_teau_de_Menthon_cliche_Eric_Sander_fa48261a30.jpg',
    category: 'découverte',
    location: 'France',
  },
  {
    title: "Itinérance douce, tout ce qu'il faut savoir pour s'y mettre à fond !",
    url: 'https://www.france.fr/fr/article/itinerance-douce/',
    image_url: 'https://asset-prod.france.fr/OPTIMIZED_6_DSC_5450_7cbc364c31.jpg',
    category: 'itinérance',
    location: 'France',
  },
  {
    title: 'Les labels à connaitre pour voyager durable',
    url: 'https://www.france.fr/fr/article/labels-durable/',
    image_url: 'https://asset-prod.france.fr/c_malp_Adobe_Stock_158859365ok_jpeg_92b0ec1c4e.jpg',
    category: 'durable',
    location: 'France',
  },
  {
    title: 'Nos itinéraires vélo coups de cœur pour parcourir la France autrement',
    url: 'https://www.france.fr/fr/article/top-itineraires-velo/',
    image_url: 'https://asset-prod.france.fr/200802_092_1_header_fbeb546afc.jfif',
    category: 'vélo',
    location: 'France',
  },
  {
    title: '7 gestes écoresponsables pour préserver la beauté du littoral',
    url: 'https://www.france.fr/fr/article/gestes-ecoresponsables-mer-littoral/',
    image_url: 'https://asset-prod.france.fr/tren_litoral_francia_f40eca347a.jpg',
    category: 'durable',
    location: 'France',
  },
];

const removeDuplicateArticles = (articles: Article[]): Article[] => {
  const uniqueTitles = new Set();
  return articles.filter((article) => {
    if (uniqueTitles.has(article.title)) {
      return false;
    }
    uniqueTitles.add(article.title);
    return true;
  });
};

const filterArticlesByUrl = (articles: Article[], lang: string): Article[] => {
  return articles.filter((article) => {
    const baseUrl = `https://www.france.fr/${lang}`;
    return article.url.startsWith(baseUrl);
  });
};

const Articles: React.FC<ArticlesProps> = ({ requirements, cityKey, postalCode }) => {
  const { t, i18n } = useTranslation();
  const [articles, setArticles] = useState<Article[]>([]);
  const lang = i18n.language;

  const formatCityKey = (key: string): string => {
    return key?.split(',')?.[0]?.trim()?.toLowerCase();
  };

  useEffect(() => {
    const formattedCityKey = formatCityKey(cityKey);

    fetchArticlesData(formattedCityKey, lang, postalCode)
      .then((data) => {
        console.log('data', data);
        console.log('data length', data ? data.length : 0);

        if (data && data.length > 0) {
          const mappedArticles = data.map((article, index) => ({
            ...article,
            category: '',
            key: `article_${index}`,
            image: article.image_url,
            noNote: true,
            place: article.location,
          }));
          console.log('mappedArticles', mappedArticles);
          const uniqueArticles = removeDuplicateArticles(mappedArticles);
          const filteredArticles = filterArticlesByUrl(uniqueArticles, lang);
          setArticles(filteredArticles);
        } else {
          console.log('Aucune donnée disponible, utilisation des données par défaut');
          // Utiliser les données par défaut si aucune donnée n'est disponible
          const mappedDefaultArticles = defaultFranceArticles.map((article, index) => ({
            ...article,
            key: `article_${index}`,
            image: article.image_url,
            noNote: true,
            place: article.location,
          }));
          const filteredDefaultArticles = filterArticlesByUrl(mappedDefaultArticles, lang);
          setArticles(filteredDefaultArticles);
          console.log('Utilisation des données par défaut', filteredDefaultArticles);
        }
      })
      .catch((e) => {
        console.error('Erreur lors du chargement des articles:', e);
        // En cas d'erreur, utiliser les données par défaut
        const mappedDefaultArticles = defaultFranceArticles.map((article, index) => ({
          ...article,
          category: '',
          key: `article_${index}`,
          image: article.image_url,
          noNote: true,
          place: article.location,
        }));
        const filteredDefaultArticles = filterArticlesByUrl(mappedDefaultArticles, lang);
        setArticles(filteredDefaultArticles);
        console.log('Utilisation des données par défaut après erreur', filteredDefaultArticles);
      });
  }, [cityKey, lang, postalCode]);

  return (
    <>
      {articles.length > 0 ? (
        <div className="Articles">
          <div className="articles-header">
            <Picto iconKey={'voyages'} width="28" height="28" />
            <span>{t('travelPlaner.Travel.articles.title')}</span>
          </div>
          <StaticCarousel label="" subLabel="" slides={articles} requirements={requirements} />
        </div>
      ) : null}
    </>
  );
};

export default Articles;
