import React from 'react';
import { PictoProps } from './index';

export const TravelSoloV2 = (props: PictoProps) => (
  <svg
    width={props.width || '40'}
    height={props.height || '40'}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon solo">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6666 11.6667C24.6666 13.9679 22.8012 15.8333 20.5 15.8333C18.1988 15.8333 16.3333 13.9679 16.3333 11.6667C16.3333 9.36548 18.1988 7.5 20.5 7.5C22.8012 7.5 24.6666 9.36548 24.6666 11.6667ZM18.8333 17.5H22.1667C25.3917 17.5 28 20.1083 28 23.3333V31.6667C28 31.8917 27.9167 32.1 27.7583 32.2583C27.6 32.4167 27.3917 32.5 27.1667 32.5H13.8333C13.6083 32.5 13.4 32.4167 13.2417 32.2583C13.0917 32.1 13 31.8917 13 31.6667V23.3333C13 20.1083 15.6083 17.5 18.8333 17.5Z"
        fill="#000000"
      />
    </g>
  </svg>
);
