import React from 'react';
import { RequirementsProps } from '../../tools/context';
import { useTranslation } from '../../tools/i18n';
import { getAssignedDisabilitiesIcon, getStoreQualityLogoUrl } from '../../tools/tools';
import { getKnowMoreLink } from '../../tools/utils';
import { Picto } from '../Picto';
import { ResponsiveImage } from '../ResponsiveImage';
import { Disability } from './SitesExplorator';

export interface RestaurantType {
  formatted_address: string;
  id: number;
  latitude: number;
  longitude: number;
  opening_hours: string;
  price_level: number;
  review_nb: number;
  shop_grade: number;
  store_name: string;
  urlimg: string;
  tourinsoft_id: number;
  quality: string;
  hebergement_accessible_france: boolean;
  assigned_disabilities: Disability[];
}

export interface RestaurantProps extends RestaurantType {
  requirements: RequirementsProps;
  noRestaurantMention?: boolean;
}

const Restaurant = (props: RestaurantProps) => {
  const {
    formatted_address,
    id,
    opening_hours,
    price_level,
    store_name,
    urlimg,
    requirements,
    noRestaurantMention,
    quality,
    hebergement_accessible_france,
    assigned_disabilities,
  } = props;

  const getRestaurantLinks = () => {
    const martiniqueTourismLink = getKnowMoreLink(requirements, {
      tourinsoft_id: props.tourinsoft_id,
    });

    const defaultImageLink = `https://cdn.cibler.io/static/tca/v2/travelPlaner/${
      noRestaurantMention ? 'hotel' : 'restaurant'
    }.jpg`;

    return {
      navigationLink:
        requirements?.partner === 'martinique' ? martiniqueTourismLink : `/etablissement/${id}`,
      imageLink: urlimg || defaultImageLink,
    };
  };

  const { navigationLink, imageLink } = getRestaurantLinks();

  return (
    <a
      className="Restaurant"
      id={`restaurant_${id}`}
      href={navigationLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ResponsiveImage
        src={imageLink}
        fallback={`https://tourismebyca.twic.pics/static/tca/v2/etablissement/${
          noRestaurantMention ? 'ou-sejourner' : 'a-table'
        }.jpg`}
        alt={store_name}
        requirements={requirements}
      />
      <div className="restaurant-content">
        <span className="name">{store_name}</span>
        <div className="informations">
          <span className="price">
            {Array(price_level).fill('€').join('')}
            {noRestaurantMention ? '' : ' - Restaurant'}
          </span>
          <span className="address">{formatted_address}</span>
          <span className="openingHours">{opening_hours}</span>
        </div>
        <div className="icons">
          {quality && getStoreQualityLogoUrl(quality) ? (
            <div className="imageContainer quality">
              <ResponsiveImage
                src={getStoreQualityLogoUrl(quality)}
                alt={quality}
                requirements={requirements}
              />
            </div>
          ) : null}
          {hebergement_accessible_france && !assigned_disabilities?.length ? (
            <div className="imageContainer">
              <ResponsiveImage
                title="Établissement Accessible - Tourisme Et Handicap France"
                src="https://cdn.cibler.io/static/france/Logos_TourismeEtHandicap/Default.png"
                alt="Logo Tourisme Et Handicap"
                requirements={requirements}
              />
            </div>
          ) : null}
          {assigned_disabilities?.length ? (
            <div className="imageContainer">
              <ResponsiveImage
                src={getAssignedDisabilitiesIcon(assigned_disabilities)}
                alt={assigned_disabilities.toString()}
                requirements={requirements}
              />
            </div>
          ) : null}
        </div>
      </div>
    </a>
  );
};

export interface RestaurantsProps {
  requirements: any;
  restaurants: RestaurantType[];
  dayOfTheWeek: number;
  lodgings?: boolean;
}

const Restaurants = (props: RestaurantsProps) => {
  const { requirements, restaurants, dayOfTheWeek, lodgings } = props;
  const { t } = useTranslation();

  if (!restaurants || restaurants.length === 0) return null;

  return (
    <div className="Restaurants" id={lodgings ? 'lodgings' : 'restaurants'}>
      <div className="restaurants-header">
        <Picto iconKey={lodgings ? 'bed' : 'cutlery'} width="28" height="28" />
        <span>{t(`travelPlaner.Travel.${lodgings ? 'whereToSleep' : 'whereToEat'}`)}</span>
      </div>
      <div className="restaurants-list">
        {restaurants.map((restaurant) => (
          <Restaurant
            {...restaurant}
            key={`restaurant_${restaurant.id}`}
            price_level={Number(restaurant.price_level) || 1}
            opening_hours={JSON.parse(restaurant.opening_hours)?.[dayOfTheWeek - 1]}
            requirements={requirements}
            noRestaurantMention={lodgings}
          />
        ))}
      </div>
    </div>
  );
};

export default Restaurants;
