import React from 'react';
import { PictoProps } from './index';

export const MapleLeaf = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '25'}
    height={height || '26'}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8644 18.835C12.8644 18.835 10.8596 20.6728 8.61681 20.7796C7.13875 20.8499 5.08459 20.4936 5.03726 20.0818C4.98993 19.6701 5.41929 18.7633 6.23067 18C6.23067 18 3.22382 17.1298 2.8418 11.1695C2.8418 9.31343 3.08048 8.88408 3.22382 8.88408C3.36717 8.88408 6.6127 8.78874 7.66276 10.0775C7.66276 10.0775 7.81625 4.9225 12.4885 1.82031C12.4885 1.82031 16.922 3.87446 17.4947 9.83948C17.4947 9.83948 20.2155 8.40671 22.1243 9.02742C22.1243 9.02742 22.697 15.9958 19.2128 18.0006C19.2128 18.0006 20.7402 19.3367 20.7876 19.8621C20.8349 20.3875 18.7828 20.8121 18.2581 20.7803C16.3263 20.6633 14.1532 20.3631 12.8651 18.8357"
      fill={fill || '#000000'}
    />
    <path
      d="M12.8644 18.835C12.8644 18.835 10.8596 20.6728 8.61681 20.7796C7.13875 20.8499 5.08459 20.4936 5.03726 20.0818C4.98993 19.6701 5.41929 18.7633 6.23067 18C6.23067 18 3.22382 17.1298 2.8418 11.1695C2.8418 9.31343 3.08048 8.88408 3.22382 8.88408C3.36717 8.88408 6.6127 8.78874 7.66276 10.0775C7.66276 10.0775 7.81625 4.9225 12.4885 1.82031C12.4885 1.82031 16.922 3.87446 17.4947 9.83948C17.4947 9.83948 20.2155 8.40671 22.1243 9.02742C22.1243 9.02742 22.697 15.9958 19.2128 18.0006C19.2128 18.0006 20.7402 19.3367 20.7876 19.8621C20.8349 20.3875 18.7828 20.8121 18.2581 20.7803C16.3263 20.6633 14.1532 20.3631 12.8651 18.8357"
      stroke={fill || '#000000'}
      strokeWidth="0.999993"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3441 23.2032C14.3441 23.2032 12.5063 22.6068 12.8647 18.8359"
      stroke={fill || '#000000'}
      strokeWidth="0.999993"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
