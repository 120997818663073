const huttopia = {
  key: 'huttopia',
  canonicalUrl: 'https://roadtrip.bivouacs.huttopia.com',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 708,
  icon: 'huttopia.png',
  headerLogo: 'huttopia.png',
  footerLogo: 'otc_monochrome.png',
  axeptioClientId: '',
  axeptioCookiesVersion: 'france.fr',
  clientUrl: 'https://www.huttopia.com/',
  proUrl: 'https://www.huttopia.com/',
  languageSelector: false,
  showTagline: false,
  sanity: {
    base: {
      projectId: 'uxzn45t2',
      dataset: 'production',
      apiVersion: '2022-12-01',
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        'skiKqkQiEVJHMOKF5ZihmijgYFBjj4EFBh2hbGj1fx17QgbDJAeH4H9wfDxs1dZ4NA4gewMaEOZsIarXaMynfE0oWRxoXlz90IuKYxqJvlcPEHnk1lRD1nVjG753mtDlPzohGh1N702SpYXXbf68MmQMhRbnhNWCGywOJ7CFtRX8eEKkIgNs',
    },
  },
  dev: {
    clientUrl: 'https://roadtrip.bivouacs.huttopia.com/',
    proUrl: 'https://roadtrip.bivouacs.huttopia.com/',
  },
  travelForceLogin: false,
};

export default huttopia;
