import React, { Fragment, useContext } from 'react';
import { RequirementsProps } from '../../../../tools/context';
import { useTranslation } from '../../../../tools/i18n';
import { getLanguageForConfig } from '../../../../tools/languageUtils';
import { capitalize } from '../../../../tools/tools';
import { getKnowMoreLink } from '../../../../tools/utils';
import ButtonV2 from '../../../ButtonV2';
import Separator from '../../../Separator';
import { TravelPlanerResultsContext } from '../../TravelPlanerResultsContext';
import TravelNightCarousel from '../TravelNightCarousel';
import TravelNightTags, { TravelNightTag } from '../TravelNightTags';

interface TravelNightPointProps {
  requirements: RequirementsProps;
  nightData: any;
}

const TravelNightPoint: React.FC<TravelNightPointProps> = ({ nightData, requirements }) => {
  const { t, i18n } = useTranslation();
  const { config } = useContext(TravelPlanerResultsContext);
  const baseLanguage =
    i18n.language?.split('_')?.[0] ||
    (typeof navigator !== 'undefined' ? navigator.language.split('-')[0] : 'fr');
  const currentLanguage = getLanguageForConfig(requirements?.config?.key, baseLanguage);

  const description =
    nightData?.[`description_${currentLanguage}`] ??
    (nightData?.description || nightData?.description_en);

  const tagsIconsMap = {
    'Vue lac': 'waterDrop',
    'Localisation exceptionnelle': 'exceptionalLocation',
  };

  const tagsContentMap = {
    'Vue lac': 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
    'Localisation exceptionnelle': 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
  };

  const formattedTags = `${nightData?.interests ?? ''}|${nightData?.services ?? ''}`
    .replace(/\|\|+/g, '|')
    ?.split('|')
    ?.concat(nightData?.tags)
    ?.filter((i) => !!i)
    ?.filter((i, index, self) => self.indexOf(i) === index)
    ?.map((interest: string) => interest.trim())
    ?.map((interest: string) => ({
      icon: tagsIconsMap?.[interest],
      title: interest,
      content: tagsContentMap?.[interest],
    }))
    ?.filter((i) => !!i.title || !!i.content) as TravelNightTag[];

  const openingTags = [
    {
      icon: 'mapleLeaf',
      title: 'Ouverture',
      content: nightData?.opening_date,
    },
  ]?.filter((i) => !!i.content) as TravelNightTag[];

  const fromPriceText = nightData?.from_price
    ? t('travelPlaner.Travel.TravelNightPoint.fromPrice', {
        price: nightData?.from_price,
      })
    : null;

  let carouselImages =
    nightData?.images ||
    (nightData?.urlimg ? [nightData?.urlimg] : [config?.siteFallbackImage?.image]);

  if (carouselImages.length < 3) carouselImages = Array(3).fill(carouselImages[0]);

  const link = getKnowMoreLink(requirements, nightData);

  return (
    <div className="TravelNightPoint">
      <TravelNightCarousel requirements={requirements} images={carouselImages} />
      <div className="TravelNightPointContainer">
        <div className="TravelNightPointHeaderContainer">
          <div className="TravelNightPointHeader">
            <h3>{nightData.name}</h3>
            <span className={nightData?.brand}>{capitalize(nightData?.brand)}</span>
          </div>
          <div className="TravelNightPointSubHeader">
            <span>
              {`${nightData?.dept ?? nightData?.city}${nightData?.dept_nb ? ` (${nightData?.dept_nb})` : ''}`}
            </span>
            {fromPriceText ? <span dangerouslySetInnerHTML={{ __html: fromPriceText }} /> : null}
          </div>
          {!!link ? (
            <ButtonV2
              className={`TravelNightPointCheckAvailabilityButton ${nightData?.brand}`}
              text={t('travelPlaner.Travel.TravelNightPoint.checkAvailability')}
              link={link}
              linkTarget="_blank"
            />
          ) : null}
        </div>
        {formattedTags?.length ? (
          <Fragment>
            <Separator />
            <TravelNightTags tags={formattedTags} />
          </Fragment>
        ) : null}
        {!!description ? (
          <Fragment>
            <Separator />
            <p>{description}</p>
          </Fragment>
        ) : null}
        {openingTags?.length ? (
          <Fragment>
            <Separator />
            <TravelNightTags tags={openingTags} />
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default TravelNightPoint;
