import React from 'react';
import { PictoProps } from './index';

export const ExceptionalLocation = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '25'}
    height={height || '26'}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9743 10.3443C12.5936 9.67768 12.1012 8.78472 11.7646 8.0608C11.611 7.72933 10.3234 4.86377 10.6151 3.71872C10.9415 2.4355 12.1049 1.7351 13.1088 1.71893C14.2649 1.70055 16.2801 3.10357 15.4482 5.21875C14.5126 7.59704 12.9743 10.3443 12.9743 10.3443Z"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.952 4.73199C12.5532 6.00271 13.6476 5.20823 13.6476 5.20823C14.3539 4.73125 14.2884 4.10213 13.9313 3.61927C13.2 2.63077 12.3254 3.27899 12.3254 3.27899C12.3254 3.27899 11.5588 3.90076 11.952 4.73199Z"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.9282 24.2243C18.2046 24.1478 19.4217 23.3137 19.4217 23.3137C20.7754 22.6309 19.8406 20.2526 19.5753 19.4067C18.1818 14.9668 17.2278 12.9648 13.1533 12.9648C9.7931 12.9648 7.27149 16.6036 5.95594 20.3062C5.60096 21.3058 5.01226 22.9778 5.88318 23.6738C6.44321 24.1214 6.94518 24.4087 14.9282 24.2243Z"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3369 17.4375C12.3369 17.4375 14.9158 20.7933 15.2767 21.2695C15.6375 21.7458 16.3078 21.6546 15.9058 22.2499C15.5038 22.846 14.58 23.7595 14.58 23.7595L12.3362 17.4941C11.7416 19.2807 10.8339 22.0743 10.0703 23.98"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.4959 23.6274C21.634 23.4613 21.8303 20.9463 21.8045 20.8008M3.93207 23.7465C3.60207 23.293 3.98572 21.0793 3.94089 20.9338"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.60156 23.2787C3.26669 22.6356 6.55411 18.5949 6.64378 18.4766"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.3984 22.8179C22.0213 22.4519 20.7888 20.9761 19.793 19.7656"
      stroke={fill || '#000000'}
      stroke-width="0.999993"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
