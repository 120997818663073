// Map of language codes to language names for display purposes (e.g. in the language selector) => 70 languages
export const languageMap: Record<string, string> = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  de: 'Deutsch',
  it: 'Italiano',
  ja: '日本語',
  zh: '中文',
  ko: '한국어',
  ru: 'Русский',
  ar: 'العربية',
  pt: 'Português',
  'pt-br': 'Português (Brasil)',
  da: 'Dansk',
  cs: 'Čeština',
  nl: 'Nederlands',
  sv: 'Svenska',
  no: 'Norsk',
  pl: 'Polski',
  tr: 'Türkçe',
  hu: 'Magyar',
  fi: 'Suomi',
  vi: 'Tiếng Việt',
  el: 'Ελληνικά',
  ro: 'Română',
  fa: 'فارسی',
  he: 'עברית',
  hi: 'हिन्दी',
  is: 'Íslenska',
  th: 'ไทย',
  id: 'Bahasa Indonesia',
  ms: 'Bahasa Melayu',
  tl: 'Tagalog',
  bn: 'বাংলা',
  ur: 'اردو',
  ne: 'नेपाली',
  my: 'ဗမာ',
  km: 'ភាសាខ្មែរ',
  lo: 'ລາວ',
  kn: 'ಕನ್ನಡ',
  ml: 'മലയാളം',
  te: 'తెలుగు',
  ta: 'தமிழ்',
  mr: 'मराठी',
  gu: 'ગુજરાતી',
  pa: 'ਪੰਜਾਬੀ',
  sa: 'संस्कृतम्',
  ka: 'ქართული',
  am: 'አማርኛ',
  or: 'ଓଡ଼ିଆ',
  si: 'සිංහල',
  uk: 'Українська',
  bg: 'Български',
  sr: 'Српски',
  mk: 'Македонски',
  et: 'Eesti',
  lv: 'Latviešu',
  lt: 'Lietuvių',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  hr: 'Hrvatski',
  bs: 'Bosanski',
  sq: 'Shqip',
  cy: 'Cymraeg',
  ga: 'Gaeilge',
  gl: 'Galego',
  af: 'Afrikaans',
  mt: 'Malti',
  eu: 'Euskara',
  az: 'Azərbaycanca',
  kk: 'Қазақ тілі',
  ky: 'Кыргызча',
  uz: 'O‘zbek',
  tk: 'Türkmen',
  mn: 'Монгол',
  ps: 'پښتو',
  sd: 'سنڌي',
  su: 'Basa Sunda',
};

export const languageCodes: Record<string, string> = {
  en: 'en-US',
  fr: 'fr-FR',
  es: 'es-ES',
  de: 'de-DE',
  it: 'it-IT',
  ja: 'ja-JP',
  zh: 'zh-CN',
  ko: 'ko-KR',
  ru: 'ru-RU',
  ar: 'ar-AE',
  pt: 'pt-PT',
  'pt-br': 'pt-BR',
  da: 'da-DK',
  cs: 'cs-CZ',
  nl: 'nl-NL',
  sv: 'sv-SE',
  no: 'no-NO',
  pl: 'pl-PL',
  tr: 'tr-TR',
  hu: 'hu-HU',
  fi: 'fi-FI',
  vi: 'vi-VN',
  el: 'el-GR',
  ro: 'ro-RO',
  fa: 'fa-IR',
  he: 'he-IL',
  hi: 'hi-IN',
  is: 'is-IS',
  th: 'th-TH',
  id: 'id-ID',
  ms: 'ms-MY',
  tl: 'tl-PH',
  bn: 'bn-BD',
  ur: 'ur-PK',
  ne: 'ne-NP',
  my: 'my-MM',
  km: 'km-KH',
  lo: 'lo-LA',
  kn: 'kn-IN',
  ml: 'ml-IN',
  te: 'te-IN',
  ta: 'ta-IN',
  mr: 'mr-IN',
  gu: 'gu-IN',
  pa: 'pa-IN',
  sa: 'sa-IN',
  ka: 'ka-GE',
  am: 'am-ET',
  or: 'or-IN',
  si: 'si-LK',
  uk: 'uk-UA',
  bg: 'bg-BG',
  sr: 'sr-RS',
  mk: 'mk-MK',
  et: 'et-EE',
  lv: 'lv-LV',
  lt: 'lt-LT',
  sk: 'sk-SK',
  sl: 'sl-SI',
  hr: 'hr-HR',
  bs: 'bs-BA',
  sq: 'sq-AL',
  cy: 'cy-GB',
  ga: 'ga-IE',
  gl: 'gl-ES',
  af: 'af-ZA',
  mt: 'mt-MT',
  eu: 'eu-ES',
  az: 'az-AZ',
  kk: 'kk-KZ',
  ky: 'ky-KG',
  uz: 'uz-UZ',
  tk: 'tk-TM',
  mn: 'mn-MN',
  ps: 'ps-AF',
  sd: 'sd-IN',
  su: 'su-ID',
};
