import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { RequirementsProps } from '../../../../tools/context';
import { ResponsiveImage } from '../../../ResponsiveImage';

interface TravelNightCarouselProps {
  requirements: RequirementsProps;
  images: string[];
}

const TravelNightCarousel: React.FC<TravelNightCarouselProps> = ({ requirements, images }) => {
  if (!images?.length) return null;

  return (
    <div className="TravelNightCarousel">
      <Swiper
        modules={[FreeMode]}
        slidesPerView="auto"
        spaceBetween={6}
        freeMode={{
          enabled: true,
          sticky: true,
          momentumRatio: 0.2,
        }}
        breakpoints={{
          768: {
            spaceBetween: 10,
          },
        }}
        className="NightCarousel"
      >
        {images.map((image, index) => (
          <SwiperSlide key={`night-carousel-${index}`} className="NightCarousel__slide">
            <ResponsiveImage
              requirements={requirements}
              src={image}
              alt={`Travel night ${index + 1}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TravelNightCarousel;
