import React, { useState } from 'react';
import { useTracking } from '../../../../tools/analytics';
import { RequirementsProps } from '../../../../tools/context';
import { useTranslation } from '../../../../tools/i18n';
import { getOrCreateCiblerId } from '../../../../tools/tools';
import { Picto } from '../../../Picto';
import { JourneyType, TravelPlannerResultsSanityProps } from '../../index';

interface TravelPlanerHubJourneyProps {
  travelPlannerResults: TravelPlannerResultsSanityProps;
  requirements: RequirementsProps;
  journey: JourneyType;
}

export default function TravelPlanerHubJourney({
  travelPlannerResults,
  requirements,
  journey,
}: TravelPlanerHubJourneyProps) {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [liked, setLiked] = useState<boolean>(false);
  const calendarIcons = {
    france: 'france-calendar',
  };
  const calendarIcon = calendarIcons[requirements?.config?.key] || 'calendar';

  const formatCityKey = (s: string) => {
    if (!!!s) return '';
    let words = s.split('-');
    words = words?.map((w) => w.charAt(0).toUpperCase() + w.slice(1));
    return words.join(' ');
  };

  const handleClickLike = async (e) => {
    e.preventDefault();

    if (liked) {
      setLiked(false);
      return;
    }

    setLiked(true);
    trackEvent(`reviewup_click`);
    const { endpoint } = requirements;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({
      ciblerId: getOrCreateCiblerId(),
      feedback: '',
      score: 1,
    });
    await fetch(`${endpoint}/api/poi/journey/${journey.id}/feedback`, {
      headers,
      method: 'POST',
      body,
    });
  };

  return (
    <a className="Journey" href={`${travelPlannerResults.path?.current}/${journey.id}`}>
      {journey.title ? <h4>{journey.title}</h4> : null}
      <div className="imageWrapper">
        <img
          src={
            journey.thumbnailurl ||
            `https://cdn.cibler.io/static/${requirements?.config?.key}/journeyPlaceholder.png`
          }
          alt={`${journey.citykey}`}
          width={'100%'}
          height={160}
        />
        <div className="destination">{journey.search || formatCityKey(journey.citykey)}</div>
      </div>
      <div className="footer">
        <div className="left" />
        <div className="right">
          <div className="tripLength">
            <Picto iconKey={calendarIcon} />
            {t(`travelPlaner.Hub.Journey.tripLength`, { count: journey.triplength })}
          </div>
          <button className={`likes ${liked ? 'liked' : ''}`} onClick={handleClickLike}>
            <Picto iconKey="like" />
            {journey.likes + (liked ? 1 : 0)}
          </button>
        </div>
      </div>
      <div className="line" />
    </a>
  );
}
