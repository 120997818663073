import LanguageDetector from 'i18next-browser-languagedetector';
import 'rc-slider/assets/index.css';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { preloadState } from './tools/apiTools';
import { Requirements } from './tools/context';
import { initI18n } from './tools/i18n';
import { getCookies, getQueryStrings } from './tools/tools';

const init = async () => {
  const strState = window.__STATE__;
  let state;
  if (strState) {
    state = JSON.parse(decodeURIComponent(strState));

    await initI18n(LanguageDetector, state);
    ReactDOM.hydrateRoot(
      document.getElementById('root'),
      <Requirements.Provider value={state.requirements}>
        <App {...state} />
      </Requirements.Provider>
    );
  } else {
    // All this part is only a dev function as the server-side rendered page should always have a state
    state = await preloadState(
      window.location.href.replace(window.location.hash, ''),
      getQueryStrings(),
      window.location.host,
      getCookies(),
      navigator.userAgent
    );

    if (state?.requirements?.partner) {
      document.cookie = `partner=${state.requirements.partner}; SameSite=Strict; Secure`;
    }
    if (state?.requirements?.environment) {
      document.cookie = `environment=${state.requirements.environment}; SameSite=Strict; Secure`;
    }

    if (state?.requirements?.config?.key) {
      var head = document.getElementsByTagName('head')[0];
      var link = document.createElement('link');
      link.id = 'customCSS';
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = `//${window.location.host}/custom/${state.requirements.config.key}.css`;
      link.media = 'all';
      head.appendChild(link);
    }

    await initI18n(LanguageDetector, state);

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <Requirements.Provider value={state.requirements}>
        <App {...state} />
      </Requirements.Provider>
    );
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};

init();
