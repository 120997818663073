import React, { useState } from 'react';
import { useTranslation } from '../../../../tools/i18n';
import ButtonV2 from '../../../ButtonV2';
import { Picto } from '../../../Picto';
import EditJourney from '../../EditJourney';
import ShareModal from '../../Modal/ShareModal';
import { useTravelPlannerResultsContext } from '../../TravelPlanerResultsContext';

interface NightDayHeaderProps {
  dayIndex: number;
}

const NightDayHeader: React.FC<NightDayHeaderProps> = ({ dayIndex }) => {
  const { t } = useTranslation();
  const { config } = useTravelPlannerResultsContext();
  const [showShare, setShowShare] = useState(false);

  const tryToShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Share my trip',
          url: 'window.location.href',
        });
      } catch (err) {
        console.error('Share failed:', err.message);
      }
      return;
    }
    setShowShare(true);
  };

  return (
    <div className="NightDayHeader" id="dayPoints">
      <div className="NightDayHeaderTop">
        {config.showAIMention ? (
          <div className="NightDayAiMention">
            <Picto iconKey="ai-mention" />
            <span>{t('travelPlaner.Travel.beta.content')}</span>
          </div>
        ) : undefined}
        <div className="NightDayHeaderButtons">
          <EditJourney picto="settings" withLabel />
          <ButtonV2
            variant="clear"
            leftIcon="share"
            text={t('travelPlaner.Travel.share')}
            onClick={tryToShare}
            className="ShareButton"
          />
        </div>
      </div>
      <div className="NightDayHeaderDay">
        <Picto iconKey="itinerary-fr" width="40" height="40" />
        {t('travelPlaner.Travel.day')}
        {`${dayIndex ? ` ${dayIndex}` : ''}`}
      </div>
      <ShareModal open={showShare} onClose={() => setShowShare(false)} />
    </div>
  );
};

export default NightDayHeader;
