import React from 'react';
import { Picto, pictos } from '../../../Picto';

export interface TravelNightTag {
  icon?: keyof typeof pictos;
  title?: string;
  content?: string;
}

interface TravelNightTagsProps {
  tags: TravelNightTag[];
}

const TravelNightTags: React.FC<TravelNightTagsProps> = ({ tags }) => {
  const filteredTags = tags?.filter((tag) => tag.icon || tag.title || tag.content);
  if (!filteredTags?.length) return null;

  return (
    <div className="TravelNightTags">
      {filteredTags.map((tag) => (
        <div className="TravelNightTag" key={tag.title}>
          {tag.icon ? <Picto iconKey={tag.icon} /> : null}
          <div className="TravelNightTagContent">
            {tag.title ? <h4>{tag.title}</h4> : null}
            {tag.content ? <p>{tag.content}</p> : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TravelNightTags;
