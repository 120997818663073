import i18next from 'i18next';
import { languageCodes } from './constants/languages';
import { RequirementsProps } from './context';
import { capitalize } from './tools';

export const mergeFooterConfig = (config: any) => {
  if (!config) return {};

  const footerOptions = config.footerOptions || {};
  const modernFooter = footerOptions.modernFooter || {};

  return {
    ...config.footer,
    ...modernFooter,
    type: footerOptions.type || 'classic',
  };
};

export const isMobile = () => {
  if (typeof window === 'undefined') return false;
  return window.innerWidth <= 768;
};

export const scrollToTop = (options: ScrollToOptions = { top: 0, behavior: 'smooth' }) => {
  if (typeof window !== 'undefined') {
    window.scrollTo(options);
  }
};

const categoryMapping = {
  sport: {
    fr: 'Sports & Loisirs',
    en: 'Sports & Leisure',
    de: 'Sport & Freizeit',
  },
  nautique: {
    fr: 'Activités nautiques',
    en: 'Water activities',
    de: 'Wasseraktivitäten',
  },
  nautisme: {
    fr: 'Activités nautiques',
    en: 'Water activities',
    de: 'Wasseraktivitäten',
  },
  aqua: {
    fr: 'Activités nautiques',
    en: 'Water activities',
    de: 'Wasseraktivitäten',
  },
  art: {
    fr: 'Art & culture',
    en: 'Art & culture',
    de: 'Kunst & Kultur',
  },
  culture: {
    fr: 'Art & culture',
    en: 'Art & culture',
    de: 'Kunst & Kultur',
  },
  monument: {
    fr: 'Art & culture',
    en: 'Art & culture',
    de: 'Kunst & Kultur',
  },
  nature: {
    fr: 'Nature & Activités Outdoor',
    en: 'Nature & Outdoor Activities',
    de: 'Natur & Outdoor Aktivitäten',
  },
  outdoor: {
    fr: 'Nature & Activités Outdoor',
    en: 'Nature & Outdoor Activities',
    de: 'Natur & Outdoor Aktivitäten',
  },
  gastro: {
    fr: 'Gastronomie',
    en: 'Gastronomy',
    de: 'Gastronomie',
  },
  hotel: {
    fr: "Hôtels & Chambres d'hôtes",
    en: 'Hotels & Guest houses',
    de: 'Hotels & Gästehäuser',
  },
  shop: {
    fr: 'Shopping',
    en: 'Shopping',
    de: 'Einkaufen',
  },
  wellness: {
    fr: 'Bien-être',
    en: 'Wellness',
    de: 'Wohlfühl',
  },
  entertainment: {
    fr: 'Sports & Loisirs',
    en: 'Sports & Leisure',
    de: 'Sport & Freizeit',
  },
  loisir: {
    fr: 'Nature & Activités Outdoor',
    en: 'Nature & Outdoor Activities',
    de: 'Natur & Outdoor Aktivitäten',
  },
  loisirs: {
    fr: 'Nature & Activités Outdoor',
    en: 'Nature & Outdoor Activities',
    de: 'Natur & Outdoor Aktivitäten',
  },
  restaurants: {
    fr: 'Gastronomie',
    en: 'Gastronomy',
    de: 'Gastronomie',
  },
  bien: {
    fr: 'Bien-être',
    en: 'Wellness',
    de: 'Wohlfühl',
  },
  commerce: {
    fr: 'Activités et expériences',
    en: 'Activities and experiences',
    de: 'Aktivitäten und Erfahrungen',
  },
  autre: {
    fr: 'Autre',
    en: 'Other',
    de: 'Andere',
  },
  musee: {
    fr: 'Musées',
    en: 'Museums',
    de: 'Museen',
  },
  spectacle: {
    fr: 'Activités et expériences',
    en: 'Activities and experiences',
    de: 'Aktivitäten und Erfahrungen',
  },
};

export const getCategoryKey = (category: string, config: any, lang: string = 'fr') => {
  if (!category) return '';

  const lowercaseCategory = category.toLowerCase();

  for (const [key, translations] of Object.entries(categoryMapping)) {
    if (lowercaseCategory.includes(key)) {
      return translations[lang] || translations['fr'] || category;
    }
  }

  return capitalize(category);
};

export const getKnowMoreLink = (requirements: RequirementsProps, poi: any): string => {
  // Vérification des paramètres requis
  if (!requirements?.config?.key || !poi) {
    console.warn('getKnowMoreLink: missing required parameters', { requirements, poi });
    return '#';
  }

  try {
    const language = i18next.language?.split('_')?.[0] || 'fr';
    const isQa = requirements.environment === 'qa';
    let languageCode = languageCodes[language];

    console.log('poi/language', requirements, poi, i18next);

    switch (requirements.config.key) {
      case 'martinique':
        // Vérification que tourinsoft_id existe
        if (!poi.tourinsoft_id) {
          console.warn('getKnowMoreLink: missing tourinsoft_id for martinique', poi);
          return '#';
        }
        return language === 'fr'
          ? `https://www.martinique.org/fr/node/${poi.tourinsoft_id}`
          : `https://www.martinique.org/en/node/${poi.tourinsoft_id}`;

      case 'huttopia':
        if (!['fr-FR', 'en-GB', 'de-DE', 'nl-NL', 'es-ES', 'pt-PT'].includes(languageCode))
          languageCode = 'en-GB';

        if (!poi.url) return null;

        return isQa
          ? `https://staging-checkout.hevasoft.com/${languageCode}${poi.url}`
          : `https://checkout.bivouacs.huttopia.com/${languageCode}${poi.url}`;

      default:
        // Vérification que l'id existe
        if (!poi.id) {
          console.warn('getKnowMoreLink: missing id for default case', poi);
          return '#';
        }
        return `/etablissement/${poi.id}`;
    }
  } catch (error) {
    console.error('Error in getKnowMoreLink:', error);
    return '#';
  }
};
