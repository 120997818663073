import React, { PropsWithChildren } from 'react';
import { RequirementsProps } from '../../tools/context';
import { Button } from '../Button';
import { TravelPlannerConfigType } from './types';

export interface FilterLayoutProps {
  config: TravelPlannerConfigType;
  callback?: () => void;
  title: string;
  back?: () => void;
  disabledNext?: boolean;
  requirements?: RequirementsProps;
  siteConfiguration: any;
}

export const FilterLayout = (props: PropsWithChildren<FilterLayoutProps>) => {
  const withSVG = props.siteConfiguration?.withSVG ?? false;
  const { config, callback, children, title, back, disabledNext } = props;

  return (
    <div className={`filterLayout ${config.newUI ? 'newUI' : ''}`}>
      <div className="layoutTitle">{title}</div>
      {children}
      {(back || callback) && (
        <div className="layoutButton">
          {back && (
            <Button
              onClick={back}
              theme="white"
              className="back"
              withPicto={config?.newUI ? 'caret-left' : 'arrow-left'}
              textLabel="common.previous"
              withSVG={withSVG}
            ></Button>
          )}
          {callback && (
            <Button
              onClick={callback}
              theme="pro"
              className="next"
              disabled={disabledNext}
              withPicto={config?.newUI ? 'caret-right' : 'arrow-right'}
              textLabel="common.next"
              withSVG={withSVG}
            ></Button>
          )}
        </div>
      )}
    </div>
  );
};
