import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import React, { useState } from 'react';
import { RequirementsProps } from '../../tools/context';
import { imageUrlFor } from '../../tools/sanity';
import ClientSideSlideshowLightbox from '../ClientSideSlideshowLightbox';
import { Copyright } from '../Copyright';

interface Image {
  title?: string;
  alt?: string;
  credit?: string;
  _key: string;
  image: SanityImageObject & {
    asset?: {
      metadata?: {
        credit?: string;
        description?: string;
        [key: string]: any; // Pour permettre d'autres métadonnées
      };
      title?: string;
      altText?: string;
      description?: string;

      [key: string]: any; // Pour permettre d'autres propriétés d'asset
    };
    [key: string]: any; // Pour permettre d'autres propriétés d'image
  };
}

type ImageGroupWidth = 'normal' | 'large' | 'full';

interface ImagesGroupProps {
  requirements: RequirementsProps;
  width: ImageGroupWidth;
  images: Image[];
}

export default function ImagesGroup({ requirements, width, images }: ImagesGroupProps) {
  const [lightboxImageIndex, setLightboxImageIndex] = useState(-1);

  const imagesWithSources = images?.map((image, index) => {
    const assetMetadata = image.image?.asset?.metadata;
    const assetTitle = image.image?.asset?.title;
    const assetAltText = image.image?.asset?.altText;
    const assetDescription = image.image?.asset?.description;

    return {
      ...image,
      src: imageUrlFor(image.image, requirements).url(),
      alt: image.alt || assetAltText || `Image ${index + 1}`,
      credit: image.credit || assetMetadata?.credit || '',
      title: image.title || assetTitle || assetDescription || '',
    };
  });

  return (
    <>
      <div className={`ImagesGroup ImagesGroup--${width} ImagesGroup--${images.length}`}>
        {imagesWithSources?.map((image, index) => (
          <button
            style={{ position: 'relative' }}
            key={index}
            className="ImagesGroup__imageContainer"
            onClick={() => {
              setLightboxImageIndex(index);
            }}
          >
            <img
              key={image._key}
              src={image.src}
              alt={image.alt || ''}
              className="ImagesGroup__imageContainer__image"
            />
            {image?.credit && (
              <Copyright title={image?.credit} className="ImagesGroup__copyright" />
            )}
          </button>
        ))}
      </div>
      <ClientSideSlideshowLightbox
        images={imagesWithSources}
        startingSlideIndex={lightboxImageIndex}
        open={lightboxImageIndex > -1}
        onClose={() => setLightboxImageIndex(-1)}
      />
    </>
  );
}
