const nordmartiniqueplanner = {
  key: 'nordmartiniqueplanner',
  canonicalUrl: 'https://www.france.fr',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 709,
  icon: 'france.ico',
  headerLogo: 'france.svg',
  footerLogo: 'otc_monochrome.png',
  axeptioClientId: '',
  axeptioCookiesVersion: 'france.fr',
  clientUrl: 'https://www.france.fr/',
  proUrl: 'https://www.france.fr/',
  languageSelector: true,
  showTagline: true,
  sanity: {
    base: {
      projectId: '8uheavee',
      dataset: 'production',
      apiVersion: '2022-12-01',
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token:
        'sk5n89iQrOQqdxc12WvYcIpriTcnZgjeNkAQdxPgwtkXq4JyavyQYY1SH6ibFrNtTQF8g3IHGxLTlClPNDV5C5cokxMdqbU9kIP9RkOUgHZJIPjjOcjrtTZDarAV90bGtlnKvoWm7sMJHrkXqYx9UCT1ggOYjQj2uofQ1EIRFrKb3uk5BQzb',
    },
  },
  dev: {
    clientUrl: 'https://www.france.fr/',
    proUrl: 'https://www.france.fr/',
  },
  travelForceLogin: false,
};

export default nordmartiniqueplanner;
