import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { OverLayParameter } from '../../App';
import { getStoreInfo } from '../../tools/ciblerAPI';
import { Configuration } from '../../tools/Constants';
import { RequirementsProps } from '../../tools/context';
import { useTranslation } from '../../tools/i18n';
import Page404 from '../404';
import ImageCarouselPopup from '../ImageCarouselPopup';
import LeafletMapLazy from '../LeafletMap/LeafletMapLazy';
import { Picto } from '../Picto';
import { ResponsiveImage } from '../ResponsiveImage';
import Contacts from './Contacts';
import CouponsPromo from './CouponsPromo';
import Header from './Header';
import Reviews, { computeAvgRating } from './Reviews';
import Sponso from './Sponso';

export interface ShopProps {
  componentStates: any;
  requirements?: RequirementsProps;
  navigation: any;
  headerButtons: any;
  hideShortcode: boolean;
  hideBookButton: boolean;
  hideReview: boolean;
  hideMail: boolean;
  hidePhone: boolean;
  hidePrice: boolean;
  enablePromoTracking: boolean;
  disableGiftCardHighlight: boolean;
  advantagesRedirectToWallet: boolean;
  giftCardText: {
    title: string;
    titleCoupon: string;
    subTitle: string;
    label: string;
    button: string;
    logo: string;
  };
  reviewTitle: string;
  setOverlay: (p: OverLayParameter) => void;
  siteConfiguration: any;
  categories: any;
}

// const getCategory = (category) =>
//   Configuration.categories[category] ? Configuration.categories[category].name : category;

const LabelBlock = ({ label }) => {
  const [img, setImg] = useState<boolean>(true);

  if (!Configuration?.labels || !label || !Configuration.labels[label]) return null;

  const labelData = Configuration.labels[label];

  return (
    <div className="label">
      {img && (
        <img
          src={`https://tourismebyca.twic.pics/static/tca/v2/labels/${label}.png`}
          alt={`Icone ${label}`}
          loading={'lazy'}
          onError={() => setImg(false)}
        />
      )}
      <div className="text">
        Labellisé <br />
        {labelData.name}
      </div>
    </div>
  );
};

const Renderer = (props: ShopProps) => {
  const { t, i18n } = useTranslation();
  const [review, setReview] = useState<any[]>(
    props.componentStates?.review?.sort((a, b) => b.id - a.id) || []
  );
  const [showCarousel, setShowCarousel] = useState<number>(0);
  const descriptionRef = useRef<HTMLDivElement>();
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState<boolean>(false);
  const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState<boolean>(false);

  const {
    giftCardText,
    componentStates,
    requirements,
    setOverlay,
    enablePromoTracking,
    hideShortcode,
    hideBookButton,
    hideReview,
    hideMail,
    hidePhone,
    hidePrice,
    disableGiftCardHighlight,
    advantagesRedirectToWallet,
    categories,
  } = props;
  const seller = componentStates?.seller;
  const images = componentStates?.images;
  const coupons = componentStates?.coupons;
  const data = componentStates?.data;
  const coupon = requirements.coupon;
  const sponso = requirements.sponso;

  const isDescriptionOverflown = () => {
    return descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight;
  };

  useEffect(() => {
    setIsDescriptionOverflowing(isDescriptionOverflown());
    window.addEventListener('resize', () => setIsDescriptionOverflowing(isDescriptionOverflown()));
  }, []);

  const getImageList = () => {
    const imageList = [...images];
    const fallbackList = [];

    if (!imageList.length) {
      imageList.push(
        `https://tourismebyca.twic.pics/static/${requirements.partner}/v2/etablissement/${seller?.frontCateg}.jpg`
      );
      fallbackList.push(
        `https://tourismebyca.twic.pics/static/tca/v2/etablissement/${seller?.frontCateg}.jpg`
      );
    }

    return imageList.map((i) => ({
      uuid: Math.random().toString(36).substring(7),
      src: i,
      fallback: fallbackList[i],
    }));
  };

  const [imageList, setImageList] = useState(getImageList());
  const generateImageGrid = () => {
    const handleImageError = (image: { uuid: string; src: string }) => {
      setImageList((p) =>
        p.filter((img, i) => i !== imageList.findIndex((i) => i.uuid === image.uuid))
      );
    };

    if (!imageList?.filter((i) => i.src !== 'denied')?.length) return null;

    return (
      <div className="images-grid-container">
        {imageList.slice(0, 3).map((s, i) => (
          <button
            onClick={() => setShowCarousel(i + 1)}
            className={!i ? 'main' : 'secondary'}
            key={`image${i}`}
          >
            <ResponsiveImage
              src={s.src}
              fallback={s.fallback}
              alt={`Image etablissement ${i}`}
              requirements={requirements}
              onError={() => handleImageError(s)}
            />
          </button>
        ))}
      </div>
    );
  };

  if (!seller)
    return (
      <Page404
        requirements={requirements}
        navigation={props.navigation}
        headerButtons={props.headerButtons}
      />
    );

  const couponBlock =
    coupon || sponso
      ? Sponso({
          coupon,
          sponso,
          giftCardText,
          data,
          seller,
          requirements,
          device: 'mobile',
        })
      : CouponsPromo({
          seller,
          coupons,
          device: 'mobile',
          requirements,
          setOverlay,
          enablePromoTracking,
          advantagesRedirectToWallet,
        });

  const categoryObject = categories[seller?.frontCateg];
  const categoryValue = categoryObject ? categoryObject.name : seller?.frontCateg;

  return (
    <div className="shop">
      {/* 1 - bloc header avec img + btn ou séjourner + nom + bloc avis + ville */}
      <Header
        seller={seller}
        review={review}
        data={data}
        rating={computeAvgRating(review)}
        category={t(`shop.categories.${seller.frontCateg}`)}
        noCoupon={!couponBlock}
        requirements={props.requirements}
        siteConfiguration={props.siteConfiguration}
        categoryValue={categoryValue}
      />
      <div className="content">
        <div className="breadcrumb">
          {seller.frontCateg ? (
            <>
              <a href={`/results?categ=${seller.frontCateg}`}>
                {categoryValue ? categoryValue : t(`shop.categories.${seller.frontCateg}`)}
              </a>
              <Picto iconKey={'arrow-right'} />
            </>
          ) : null}
          {seller.name}
        </div>
        {couponBlock}
        {generateImageGrid()}
        {!!showCarousel && (
          <ImageCarouselPopup
            list={imageList.map((i) => ({ image: i.src }))}
            closeCallback={() => setShowCarousel(0)}
            initialPage={showCarousel - 1}
            requirements={props.requirements}
          />
        )}
        <div
          className={`description ${isDescriptionExpanded ? 'expanded' : ''}`}
          ref={descriptionRef}
          dangerouslySetInnerHTML={{
            __html:
              i18n.language === 'fr' || i18n.language === 'fr-FR' || !i18n.language
                ? data.description
                : data[`description_${i18n.language}`] || data.description_en || data.description,
          }}
        />
        {isDescriptionOverflowing && (
          <button
            className={`caret ${isDescriptionExpanded ? 'expanded' : ''}`}
            onClick={() => setIsDescriptionExpanded((prevState) => !prevState)}
          />
        )}
        {data.photo_credit ? (
          <div>
            {t('shop.photoCredit')} : {data.photo_credit}
          </div>
        ) : null}
        <div className="sideBlock">
          {/* 3 - Bloc "vos avantages" */}
          {coupon || sponso ? (
            <Sponso
              coupon={coupon}
              sponso={sponso}
              data={data}
              seller={seller}
              device={'desktop'}
              requirements={requirements}
              giftCardText={giftCardText}
            />
          ) : (
            <CouponsPromo
              seller={seller}
              coupons={coupons}
              device={'desktop'}
              requirements={props.requirements}
              setOverlay={setOverlay}
              enablePromoTracking={enablePromoTracking}
              advantagesRedirectToWallet={advantagesRedirectToWallet}
            />
          )}
          {/* 4 - Bloc contact et résa */}
          <Contacts
            requirements={props.requirements}
            seller={seller}
            data={data}
            hideShortcode={hideShortcode}
            hideBookButton={hideBookButton}
            hideMail={hideMail}
            hidePhone={hidePhone}
            hidePrice={hidePrice}
          />
          {!(coupon || sponso) && (
            <Sponso
              data={data}
              seller={seller}
              requirements={requirements}
              giftCardText={giftCardText}
              disableGiftCardHighlight={disableGiftCardHighlight}
            />
          )}
          {Object.keys(data)
            ?.filter((key) => key.startsWith('label'))
            ?.map((key) => <LabelBlock label={data[key]} key={key} />)}
        </div>

        {/* 5 - Bloc "Partagez cette fiche pro" */}

        {/* 6 - Vous êtes ici  */}
        {seller.latitude && seller.longitude && (
          <div className="mapBlock">
            <div className="title">{t('shop.findUs')}</div>
            <div className="map" style={{ height: '300px' }}>
              <LeafletMapLazy
                center={[parseFloat(seller.latitude), parseFloat(seller.longitude)]}
                zoom={15}
                markers={[
                  {
                    position: [parseFloat(seller.latitude), parseFloat(seller.longitude)],
                    popup: seller.name,
                  },
                ]}
                customerKey={requirements?.config?.key}
              />
            </div>
            <div className="address">
              <span nonce="__nonce__" style={{ fontWeight: 700 }}>
                {seller.name}
              </span>
              {' - '}
              <span>{data.street}</span>
              {' - '}
              <span>
                {data.zipcode} {data.city}
              </span>
            </div>
          </div>
        )}

        {/* 7 - Rencontrez votre hôte */}

        {/* 8 - Avis de nos voyageurs */}
        {hideReview === true ? null : <Reviews {...props} review={review} setReview={setReview} />}
      </div>
    </div>
  );
};

const preloader = async (data, requirements) => {
  const param = requirements.parameters.length ? requirements.parameters[0] : requirements.sellerId;
  const sellerId = param?.split('-')?.pop();

  if (!!!sellerId) return null;

  return await getStoreInfo(param, requirements);
};

const headers = (state) => {
  const headers = [];

  if (state?.seller?.name) {
    headers.push(`<meta name="name" content="${state?.seller?.name}">`);
  }
  if (state?.data) {
    headers.push(
      `<meta name="address" content="${state.data.street || ''} ${
        state.data.zipcode || ''
      } ${state.data.city || ''}">`
    );
    headers.push(`<meta name="description" content="${state?.data.description || ''}">`);

    if (state.data.noindex === 'true') {
      headers.push('<meta name="robots" content="noindex">');
      headers.push('<meta name="googlebot" content="noindex">');
    }
  }

  return headers;
};

const Shop = { Renderer, preloader, headers };

export default Shop;
export { Renderer, preloader };
