import { UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from '../../../tools/i18n';
import CustomImage from '../../CustomImage';
import { Picto } from '../../Picto';
import { ResponsiveImage } from '../../ResponsiveImage';
import { TravelPlanerResultsContext } from '../TravelPlanerResultsContext';
import { Day, Item } from './index';
import PopupItemContainer from './PopupItemContainer';

interface Props {
  overlay?: boolean;
  day: Day;
  dayIndex: number;
  items: Item[];
  isHovered?: boolean;
  nightItem?: Item;
  handleDeleteDay(id: UniqueIdentifier): void;
  handleDeleteItem(id: UniqueIdentifier): void;
}

export default function PopupDayContainer(props: Props) {
  const { t } = useTranslation();
  const { config, requirements } = useContext(TravelPlanerResultsContext);
  const { overlay, day, dayIndex, items, isHovered, nightItem, handleDeleteDay, handleDeleteItem } =
    props;

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: day.id,
    data: {
      type: 'day',
      day,
    },
  });

  const itemsId = useMemo(() => items.map((item) => item.id), [items]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={`PopupDay ${isDragging ? 'dragging' : ''} ${
        overlay ? 'overlay' : ''
      } ${isHovered ? 'hovered' : ''} ${nightItem ? 'has-night-item' : ''}`}
      ref={setNodeRef}
      nonce="__nonce__"
      style={style}
    >
      <div className="popupDayHeader">
        <button className="deleteButton" onClick={() => handleDeleteDay(day.id)}>
          <div className="deleteButtonContent">
            <Picto iconKey="minus" width="6" height="6" />
          </div>
        </button>
        <div className="popupDayHeaderNightItemImage">
          {nightItem?.data?.urlimg || nightItem?.data?.tca_categ ? (
            <ResponsiveImage
              requirements={requirements}
              notLazy
              src={nightItem.data.urlimg}
              alt={nightItem.data.urlimg || nightItem.data.tca_categ}
              fallback={`https://tourismebyca.twic.pics/static/${requirements.partner}/v2/etablissement/${nightItem.data.tca_categ}.jpg`}
              fallback2={`https://tourismebyca.twic.pics/static/tca/v2/etablissement/${nightItem.data.tca_categ}.jpg`}
            />
          ) : (
            <CustomImage
              requirements={requirements}
              image={config.siteFallbackImage.image || ''}
              alt={config.siteFallbackImage.alt || ''}
              credit={config.siteFallbackImage.credit || ''}
            />
          )}
        </div>
        <div className="popupDayHeaderContent">
          <span>
            {t('travelPlaner.Travel.day')} {dayIndex + 1}
            {nightItem?.data?.name || nightItem?.data?.title
              ? ` - ${nightItem.data?.name || nightItem.data?.title}`
              : ''}
          </span>
          {nightItem && (
            <div className="nightItem">
              {nightItem.data?.city && (
                <span className="popupDayHeaderSubtitle">{nightItem.data.city}</span>
              )}
            </div>
          )}
        </div>
        <div {...attributes} {...listeners} className="handle">
          <Picto iconKey="burger-fr" width="14" height="14" />
        </div>
      </div>
      <SortableContext items={itemsId}>
        <div className="popupDayContent">
          {items.map((item) => (
            <PopupItemContainer key={item.id} item={item} handleDeleteItem={handleDeleteItem} />
          ))}
        </div>
      </SortableContext>
    </div>
  );
}
