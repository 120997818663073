import React from 'react';
import { RequirementsProps } from '../../../tools/context';
import { useTranslation } from '../../../tools/i18n';
import StaticCarousel from '../../Carousel/StaticCarousel';
import NightDayHeader from './NightDayHeader';
import TravelNightPoint from './TravelNightPoint';

import { Picto } from '../../Picto';

const DEFAULT_IMAGE =
  'https://cdn.sanity.io/images/uxzn45t2/production/c97934a4a559be0c144f69e2b4bbc6a2527c89c7-1440x770.jpg';

export interface TravelDaysNightProps {
  data: any;
  requirements: RequirementsProps;
  dayIndex?: number;
}

export const TravelDaysNight: React.FC<TravelDaysNightProps> = ({
  data,
  dayIndex,
  requirements,
}) => {
  const { t } = useTranslation();
  const nightData = data.find((d: any) => d.key?.startsWith('night'))?.value?.[0];
  const activities = data
    .filter((d: any) => !d.key?.startsWith('night'))
    .flatMap((d: any) => d.value || [])
    .map((activity: any) => ({
      id: activity.id,
      title: `${activity.name || activity.store_name || ''}`,
      place: activity.city || '',
      image: activity.urlimg || '',
      fallbackImage: DEFAULT_IMAGE,
      url: activity.url || '',
      description: activity.description || '',
      requirements: requirements,
    }));
  if (!nightData) return null;
  console.log('activities', activities);

  return (
    <div className="travelDaysNight">
      <NightDayHeader dayIndex={dayIndex} />
      <TravelNightPoint key={data?.[0].id} nightData={nightData} requirements={requirements} />
      <div className="Articles">
        <div className="articles-header">
          <Picto iconKey="voyages" width="28" height="28" />
          <span>{t('travelPlaner.Travel.articles.title')}</span>
        </div>
        <StaticCarousel
          label=""
          subLabel=""
          slides={activities}
          requirements={requirements}
          className="travel-carousel"
        />
      </div>
    </div>
  );
};
